import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
// Twitter icon import
// import { FaTwitterSquare } from 'react-icons/fa';

// Styled components
const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const PageTitle = styled.h1`
  margin-bottom: 2rem;
  text-align: center;
`;

const TwoColumnLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormColumn = styled.div`
  flex: 1;
  min-width: 0;
`;

const InfoColumn = styled.div`
  flex: 1;
  min-width: 0;
`;

const FormContainer = styled.div`
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const RequiredAsterisk = styled.span`
  color: red;
  margin-left: 2px;
`;

const Input = styled(Field)`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 150px;
`;

const ErrorText = styled.div`
  color: #e53e3e;
  margin-top: 0.25rem;
  font-size: 0.875rem;
`;

const SubmitButton = styled.button`
  background-color: #28346c;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.div`
  background-color: #c6f6d5;
  color: #2f855a;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const ErrorMessageContainer = styled.div`
  background-color: #fed7d7;
  color: #c53030;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const InfoCard = styled.div`
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const InfoTitle = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #28346c;
`;

const ContactInfoItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const IconWrapper = styled.div`
  margin-right: 1rem;
  color: #28346c;
  font-size: 1.25rem;
`;

const ContactText = styled.div`
  line-height: 1.5;
`;

const MapContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const MapIframe = styled.iframe`
  width: 100%;
  height: 300px;
  border: none;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialMediaLink = styled.a`
  color: #28346c;
  font-size: 2rem;
  transition: color 0.2s;

  &:hover {
    color: #2980b9;
  }
`;

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

// EmailJS configuration
const EMAILJS_SERVICE_ID = "service_t4fen8k";
const EMAILJS_TEMPLATE_ID = "template_ubqlrwc";
const EMAILJS_PUBLIC_KEY = "nHVkLBKgLW3lC8jRx";

// Phone formatter
const formatPhoneNumber = (value) => {
  if (!value) return value;
  
  // Remove all non-digits
  const phoneNumber = value.replace(/[^\d]/g, '');
  
  // Take only the first 10 digits
  const phoneNumberTruncated = phoneNumber.substring(0, 10);
  
  // Apply formatting based on length
  if (phoneNumberTruncated.length < 4) {
    return phoneNumberTruncated;
  } else if (phoneNumberTruncated.length < 7) {
    return `(${phoneNumberTruncated.slice(0, 3)}) ${phoneNumberTruncated.slice(3)}`;
  }
  return `(${phoneNumberTruncated.slice(0, 3)}) ${phoneNumberTruncated.slice(3, 6)}-${phoneNumberTruncated.slice(6, 10)}`;
};

// Validation schema
const contactSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(/^\(\d{3}\) \d{3}-\d{4}$|^\d{10}$|^\(\d{3}\) \d{3}$|^\(\d{3}\)$|^\d{3}$|^\d{6}$|^\(\d{3}\) \d{3}-\d{1,3}$/, 
      'Please enter a valid phone number')
    .test('len', 'Phone number must be exactly 10 digits', 
      value => !value || value.replace(/[^\d]/g, '').length === 10 || value.replace(/[^\d]/g, '').length < 10),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required').min(10, 'Message should be at least 10 characters')
});

const ContactUs = () => {

  useEffect(() => {
    document.title = "Contact Capstone | Healthcare Staffing & Recruitment Support";
      
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Get in touch with Capstone for expert healthcare staffing solutions. Contact us today for agency nurse jobs, travel therapy positions, and certified surgical tech jobs.');
    }
  }, []);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Reset success message after 5 seconds
  useEffect(() => {
    let timeoutId;
    if (success) {
      timeoutId = setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [success]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setIsSubmitting(true);
      setError('');
      
      console.log("Form values:", values); // Debug: check values being submitted

      // Prepare template parameters - match these with your EmailJS template variables
      const templateParams = {
        from_name: `${values.firstName} ${values.lastName}`,
        reply_to: values.email,
        phone: values.phone,
        subject: values.subject,
        message: values.message,
        to_email: "test@capstone.net", // Your target email
      };

      // Send email using EmailJS
      const response = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        templateParams,
        EMAILJS_PUBLIC_KEY
      );

      console.log('Email sent successfully:', response);
      setSuccess(true);
      resetForm();
    } catch (err) {
      console.error('Failed to send email:', err);
      setError(err.message || 'Failed to send message. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <VisuallyHidden>Get in Touch with Capstone – Your Healthcare Staffing Partner</VisuallyHidden>
    <div>
      <PageContainer>
        <PageTitle>Let's Talk!</PageTitle>
        
        {success && (
          <SuccessMessage>
            Your message has been sent successfully! We'll get back to you soon.
          </SuccessMessage>
        )}
        
        {error && (
          <ErrorMessageContainer>
            {error}
          </ErrorMessageContainer>
        )}
        
        <TwoColumnLayout>
          {/* Form Column */}
          <FormColumn>
            <FormContainer>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phone: '',
                  subject: '',
                  message: ''
                }}
                validationSchema={contactSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, getFieldProps, handleBlur, handleChange, values, setFieldValue }) => (
                  <Form>
                    <FormGroup>
                      <Label htmlFor="firstName">
                        First Name<RequiredAsterisk>*</RequiredAsterisk>
                      </Label>
                      <Input type="text" id="firstName" name="firstName" />
                      <ErrorText>{errors.firstName && touched.firstName && errors.firstName}</ErrorText>
                    </FormGroup>
                    
                    <FormGroup>
                      <Label htmlFor="lastName">
                        Last Name<RequiredAsterisk>*</RequiredAsterisk>
                      </Label>
                      <Input type="text" id="lastName" name="lastName" />
                      <ErrorText>{errors.lastName && touched.lastName && errors.lastName}</ErrorText>
                    </FormGroup>
                    
                    <FormGroup>
                      <Label htmlFor="email">
                        Email<RequiredAsterisk>*</RequiredAsterisk>
                      </Label>
                      <Input type="email" id="email" name="email" />
                      <ErrorText>{errors.email && touched.email && errors.email}</ErrorText>
                    </FormGroup>
                    
                    <FormGroup>
                      <Label htmlFor="phone">
                        Phone<RequiredAsterisk>*</RequiredAsterisk>
                      </Label>
                      <Input 
                        type="tel" 
                        id="phone" 
                        name="phone"
                        onChange={(e) => {
                          const formattedPhone = formatPhoneNumber(e.target.value);
                          setFieldValue("phone", formattedPhone);
                        }}
                        value={values.phone}
                      />
                      <ErrorText>{errors.phone && touched.phone && errors.phone}</ErrorText>
                    </FormGroup>
                    
                    <FormGroup>
                      <Label htmlFor="subject">
                        Subject<RequiredAsterisk>*</RequiredAsterisk>
                      </Label>
                      <Input type="text" id="subject" name="subject" />
                      <ErrorText>{errors.subject && touched.subject && errors.subject}</ErrorText>
                    </FormGroup>
                    
                    <FormGroup>
                      <Label htmlFor="message">
                        Message<RequiredAsterisk>*</RequiredAsterisk>
                      </Label>
                      <TextArea 
                        id="message" 
                        name="message" 
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                      />
                      <ErrorText>{errors.message && touched.message && errors.message}</ErrorText>
                    </FormGroup>
                    
                    <SubmitButton type="submit" disabled={isSubmitting}>
                      {isSubmitting ? 'Sending...' : 'Send Message'}
                    </SubmitButton>
                  </Form>
                )}
              </Formik>
            </FormContainer>
          </FormColumn>
          
          {/* Contact Info Column */}
          <InfoColumn>
            <InfoCard>
              <InfoTitle>Contact Information</InfoTitle>
              
              <ContactInfoItem>
                <IconWrapper>
                  <FaMapMarkerAlt />
                </IconWrapper>
                <ContactText>
                  1540 High St Ste 101, Des Moines, IA 50309
                </ContactText>
              </ContactInfoItem>
              
              <ContactInfoItem>
                <IconWrapper>
                  <FaEnvelope />
                </IconWrapper>
                <ContactText>
                  <a href="mailto:contact@capstone.net">contact@capstone.net</a>
                </ContactText>
              </ContactInfoItem>
              
              <ContactInfoItem>
                <IconWrapper>
                  <FaPhone />
                </IconWrapper>
                <ContactText>
                  <a href="tel:+15154462266">(515) 446-2266</a>
                </ContactText>
              </ContactInfoItem>
              

              
              <InfoTitle>Follow Us</InfoTitle>
              <SocialMediaContainer>
                <SocialMediaLink href="https://www.facebook.com/capstonehealthjobs" target="_blank" rel="noopener noreferrer">
                  <FaFacebookSquare />
                </SocialMediaLink>
                {/* Twitter account not available yet
                <SocialMediaLink href="#" target="_blank" rel="noopener noreferrer">
                  <FaTwitterSquare />
                </SocialMediaLink>
                */}
                <SocialMediaLink href="https://www.instagram.com/capstonehealthjobs" target="_blank" rel="noopener noreferrer">
                  <FaInstagramSquare />
                </SocialMediaLink>
                <SocialMediaLink href="https://www.linkedin.com/company/capstonehealthjobs" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin />
                </SocialMediaLink>
              </SocialMediaContainer>
            </InfoCard>
            
            <MapContainer>
              <a 
                href="https://www.google.com/maps/place/Capstone+Health/@41.5868812,-93.6386225,17z/data=!3m1!4b1!4m6!3m5!1s0x87ee995b5d4dc34f:0x5f8e0db320657c7e!8m2!3d41.5868812!4d-93.6386225!16s%2Fg%2F11t5dkhg02?entry=ttu&g_ep=EgoyMDI1MDMwOC4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapIframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2983.829!2d-93.6386225!3d41.5868812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ee995b5d4dc34f%3A0x5f8e0db320657c7e!2sCapstone%20Health!5e0!3m2!1sen!2sus!4v1709906225634!5m2!1sen!2sus"
                  title="Capstone Health Google Map Location"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </a>
            </MapContainer>
          </InfoColumn>
        </TwoColumnLayout>
      </PageContainer>
      </div>
    </div>
  );
};

export default ContactUs;