// src/pages/CathLabs.jsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackToHomeLink from '../components/BackToHomeLink';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  background-color: #28346c;
  color: white;
  padding: 4rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: center;
`;

const HeroTitle = styled.h1`
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-size: 2.5rem;
`;

const HeroDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: white;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const CTASection = styled.div`
  background-color: #f8f9fa;
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CTATitle = styled.h2`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.8rem;
`;

const CTADescription = styled.p`
  color: #54698d;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #28346c;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1e2756;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const StatsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
`;

const StatCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StatText = styled.p`
  color: #54698d;
  line-height: 1.6;
`;

const ContentSection = styled.div`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  color: #28346c;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  text-align: center;
`;

const JobsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
`;

const JobCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const JobTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const JobDescription = styled.p`
  color: #54698d;
  line-height: 1.6;
`;

const ContactBanner = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContactText = styled.h3`
  color: #28346c;
  margin: 0;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const CathLabs = () => {

  useEffect(() => {
    document.title = "Cath Lab Technologist Jobs | Find Top Opportunities with Capstone";
      
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Discover exciting Cath Lab Technologist jobs with Capstone. Connect with leading healthcare facilities and advance your career in cardiac catheterization labs.');
    }
  }, []);


  return (
    <div>
      <div>
      <VisuallyHidden>Cath Lab Technologist Jobs – Grow Your Career in Cardiac Care</VisuallyHidden>
      <PageContainer>
      <BackToHomeLink />
        <HeroSection>
          <HeroTitle>Cath Lab</HeroTitle>
          <HeroDescription>
            Step into the dynamic realm of Cath Lab staffing with Capstone Health as your dedicated partner. Our comprehensive services cater to various specialized roles that align with your expertise. Whether you're a Cath Lab RN, Cath Lab LPN, Cardiac Cath Lab Technologist, or a Cardiovascular Technologist, we're committed to advancing your career. Our vast network of healthcare institutions offers opportunities to collaborate with accomplished medical professionals, leaving a profound impact on patient well-being. Join Capstone Health today and embark on a fulfilling journey in the world of Cath Lab staffing, where your skills and dedication take center stage.
          </HeroDescription>
        </HeroSection>

        <CTASection>
          <CTATitle>Seeking Cath Labs Opportunities?</CTATitle>
          <CTADescription>
            Discover diverse Cath Lab job opportunities across the nation through Capstone Health Inc.
            Embark on your journey in the field of Cath Lab and begin shaping your career today.
          </CTADescription>
          <Button to="/contact">Contact us</Button>
        </CTASection>

        <SectionTitle>Facts about demand of Cath Lab professionals</SectionTitle>
        <StatsSection>
          <StatCard>
            <StatText>The demand for clinical laboratory technologists and technicians, which include cath lab techs, is projected to grow 7% from 2021 to 2031</StatText>
          </StatCard>
          <StatCard>
            <StatText>About 25,600 openings for clinical laboratory technologists and technicians are projected each year</StatText>
          </StatCard>
          <StatCard>
            <StatText>The demand for nurses might increase by 6% from 2019 to 2029, which is faster than the average for all occupations</StatText>
          </StatCard>
        </StatsSection>

        <ContentSection>
          <JobsGrid>
            <JobCard>
              <JobTitle>Cath Lab RN</JobTitle>
              <JobDescription>
                As a Cath Lab RN with Capstone Health, you'll play a pivotal role in cardiovascular care. Your responsibilities include assisting during cardiac procedures, monitoring patients' vital signs, and ensuring a safe and comfortable environment. Collaborating with a skilled medical team, you'll contribute to accurate diagnoses and effective treatments for cardiovascular conditions. Join Capstone Health's network to harness your expertise and make a lasting impact on patient well-being in the dynamic Cath Lab setting.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Cath Lab LPN</JobTitle>
              <JobDescription>
                A cath lab LPN has various responsibilities, such as:

                Preparing patients for catheterization procedures, which may include checking their vital signs, administering medications, obtaining consent forms, and shaving the insertion site.
                Assisting the cardiologist and other members of the cath lab team during the procedures, which may include handing instruments, monitoring the patient's condition, and recording data.
                Providing post-procedure care for patients, which may include dressing the wound, checking for bleeding or infection, educating patients and families about discharge instructions and follow-up care, and documenting the care provided.
                Maintaining a safe and sterile environment in the cath lab, which may include cleaning and stocking equipment, following infection control protocols, and reporting any issues or malfunctions.
                A cath lab LPN works under the supervision of a registered nurse (RN) or a physician. They may work in hospitals, clinics, or independent cardiac centers. They may work shifts that vary in length and frequency, depending on the needs of the facility and the availability of staff.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Cardiac Cath Lab Technologist</JobTitle>
              <JobDescription>
                Embark on an impactful journey as a Cardiac Cath Lab Technologist with Capstone Health. Your pivotal role involves preparing and maintaining sophisticated cath lab equipment, ensuring its proper function during cardiac procedures. Collaborate closely with medical teams to assist in diagnostic and interventional procedures, including catheterizations and stent placements. Your expertise contributes to accurate data recording and patient comfort. Capstone Health provides an avenue to excel in the dynamic field of cardiac care, working alongside professionals committed to enhancing patient outcomes in the cath lab setting.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Cardiovascular Technologist</JobTitle>
              <JobDescription>
                Elevate your career as a Cardiovascular Technologist with Capstone Health. Your expertise drives excellence in diagnosing and treating heart conditions. Operating specialized equipment, you assist during cardiac procedures, such as echocardiograms and stress tests. Collaborate with medical teams to ensure accurate data collection and patient comfort. Capstone Health offers you the platform to make a profound impact on cardiovascular care. Join our network of professionals dedicated to advancing patient outcomes and contributing to cutting-edge advancements in the field of cardiology.
              </JobDescription>
            </JobCard>
          </JobsGrid>
        </ContentSection>

        <ContactBanner>
          <ContactText>DO YOU HAVE A QUESTION?</ContactText>
          <Button to="tel:+15154462266">Call (515) 446-2266</Button>
        </ContactBanner>
      </PageContainer>
      </div>
    </div>
  );
};

export default CathLabs;