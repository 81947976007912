// src/pages/NotFound.jsx
import React from 'react';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  padding: 4rem;
  text-align: center;
`;

function NotFound() {
  return (
    <NotFoundContainer>
      <h1>404</h1>
      <p>Page Not Found</p>
      <p>
        Oops! The page you&apos;re looking for doesn&apos;t exist. 
        <br />
        <a href="/">Go back home</a>
      </p>
    </NotFoundContainer>
  );
}

export default NotFound;
