// src/pages/Imaging.jsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackToHomeLink from '../components/BackToHomeLink';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  background-color: #28346c;
  color: white;
  padding: 4rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: center;
`;

const HeroTitle = styled.h1`
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-size: 2.5rem;
`;

const HeroDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: white;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const CTASection = styled.div`
  background-color: #f8f9fa;
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CTATitle = styled.h2`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.8rem;
`;

const CTADescription = styled.p`
  color: #54698d;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #28346c;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1e2756;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ContentSection = styled.div`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  color: #28346c;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  text-align: center;
`;

const InfoCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const InfoTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const InfoText = styled.p`
  color: #54698d;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const BenefitsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
`;

const BenefitCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
`;

const BenefitText = styled.p`
  color: #54698d;
  line-height: 1.5;
  margin: 0;
`;

const JobsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
`;

const JobCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const JobTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const JobDescription = styled.p`
  color: #54698d;
  line-height: 1.6;
`;

const ContactBanner = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContactText = styled.h3`
  color: #28346c;
  margin: 0;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const Imaging = () => {

  useEffect(() => {
    document.title = "Imaging Jobs | Advance Your Career in Medical Imaging with Capstone";
      
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Find top medical imaging jobs with Capstone. Explore rewarding opportunities for radiologic technologists, sonographers, and imaging professionals in leading healthcare facilities.');
    }
  }, []);

  return (
    <div>
       <div>
       <VisuallyHidden>Imaging Jobs – Explore Radiology & Diagnostic Imaging Careers with Capstone</VisuallyHidden>
      <PageContainer>
      <BackToHomeLink />
        <HeroSection>
          <HeroTitle>Capstone Health Imaging Staffing Services</HeroTitle>
          <HeroDescription>
            Empowering Healthcare Professionals in the World of Medical Imaging. Whether you're a skilled Cardiovascular Technologist, CT Technologist, Echo Technologist, Interventional Radiologist, Mammography Technologist, MRI Technologist, Ultrasound Technologist, Vascular Tech, or X-ray technologist, Capstone Health is your gateway to a rewarding career. We bring together expertise and opportunity to match your skills with leading healthcare facilities. With a range of specialized roles in the imaging field, we empower you to make a significant impact on patient care. Join Capstone Health and embark on a fulfilling journey in medical imaging. Your passion, our priority.
          </HeroDescription>
        </HeroSection>

        <CTASection>
          <CTATitle>Seeking Imaging Technologist Opportunities?</CTATitle>
          <CTADescription>
            Discover a diverse array of imaging technologist roles across the nation with Capstone Health. Begin your journey in the field of medical imaging today.
          </CTADescription>
          <Button to="/contact">Contact Us</Button>
        </CTASection>

        <ContentSection>
          <InfoCard>
            <InfoTitle>Elevate Your Career in Medical Imaging with Capstone Health</InfoTitle>
            <InfoText>
              Ready to Advance Your Career in Medical Imaging and Make a Significant Impact on Patient Care? If you're a committed healthcare expert searching for the ideal role within an organization that aligns with your goals, Capstone Health is your partner. With our extensive industry insight, we effortlessly connect you with facilities that match your aspirations. Capstone Health has a strong track record of matching skilled imaging technologists with top healthcare institutions. Rely on our expertise for a seamless job search, ensuring a positive journey towards achieving your professional goals in medical imaging.
            </InfoText>
          </InfoCard>
          
          <SectionTitle>Advantages for Capstone Health Imaging Technologist</SectionTitle>
          <BenefitsSection>
            <BenefitCard>
              <BenefitText>Comprehensive healthcare plans</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Dental and vision insurance</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Life insurance coverage</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Access to mental health resources</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Financial coaching services</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Supplementary income replacement plans</BenefitText>
            </BenefitCard>
          </BenefitsSection>

          <JobsGrid>
            <JobCard>
              <JobTitle>CT Technologist</JobTitle>
              <JobDescription>
                As a CT Technologist, you'll be at the forefront of medical imaging, utilizing advanced equipment to capture detailed cross-sectional images of patients' anatomy. Your expertise will help diagnose and guide treatment for various medical conditions. Collaborating closely with medical teams, you'll ensure patient comfort, administer contrast agents when needed, and maintain equipment functionality. Your role is pivotal in providing accurate and crucial information to physicians. Join Capstone Health to channel your CT Technologist skills into a rewarding career journey.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Echo Technologist</JobTitle>
              <JobDescription>
                As an Echo Technologist, you'll specialize in using ultrasound technology to create images of the heart's structures and functions. Your expertise aids in diagnosing cardiovascular conditions, guiding treatment decisions, and monitoring patients' heart health. Working closely with medical teams, you'll perform echocardiograms, interpret results, and ensure patients' comfort throughout the process. Your role is instrumental in enhancing patient care and outcomes. Join Capstone Health to utilize your Echo Technologist skills and embark on a fulfilling career path in healthcare imaging.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Interventional Radiologist</JobTitle>
              <JobDescription>
                As an Interventional Radiologist, you'll play a vital role in diagnosing and treating medical conditions using minimally invasive procedures guided by imaging technologies. Your expertise helps avoid the need for traditional surgery, reducing patient discomfort and recovery times. Collaborating closely with medical teams, you'll perform procedures like angioplasty, stent placements, and biopsies, while ensuring patient safety and accurate image interpretation. Capstone Health offers you a platform to utilize your skills and contribute to cutting-edge medical interventions that make a significant impact on patient care.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Mammography Technologist</JobTitle>
              <JobDescription>
                As a Mammography Technologist, you'll specialize in breast imaging, playing a crucial role in detecting early signs of breast cancer. Your skills in positioning patients and operating mammography equipment are essential for producing clear and accurate images. By providing compassionate care during screenings, you contribute to women's health and well-being. Capstone Health offers you the opportunity to make a difference by joining a team dedicated to preventive healthcare and early disease detection, ensuring a positive impact on patients' lives.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>MRI Technologist</JobTitle>
              <JobDescription>
                As an MRI Technologist, you'll excel in capturing detailed images of internal body structures using advanced magnetic resonance imaging technology. Your expertise ensures accurate diagnosis and treatment planning for various medical conditions. Join Capstone Health to leverage your skills in a dynamic healthcare environment. With our support, you can contribute to improved patient outcomes by providing high-quality MRI scans. Elevate your career by partnering with us and making a significant impact on healthcare through cutting-edge imaging technology.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Ultrasound Technologist</JobTitle>
              <JobDescription>
                As an Ultrasound Technologist, you play a pivotal role in using sound waves to produce images of patients' internal structures. Your skillful scans aid in diagnosing medical conditions and guiding treatments. Capstone Health offers you an opportunity to excel in your career by connecting you with top-tier healthcare facilities. Join our network to showcase your ultrasound expertise and contribute to accurate diagnoses and patient care. Your proficiency in imaging technology can make a meaningful difference in healthcare outcomes.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Vascular Technologist</JobTitle>
              <JobDescription>
                A Vascular Technologist is a skilled medical professional who specializes in using ultrasound technology to assess and diagnose disorders related to blood vessels and circulation. They perform non-invasive tests to evaluate the blood flow in arteries and veins, helping physicians detect conditions like blood clots, blockages, and vascular diseases. Vascular Technologists play a crucial role in aiding patient diagnoses and treatment planning, contributing to improved vascular health and overall patient well-being.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>X-Ray Technologist</JobTitle>
              <JobDescription>
                An X-Ray Technologist, also known as a Radiologic Technologist, is a vital member of the healthcare team responsible for operating X-ray equipment to capture internal images of patients' bodies. These images assist physicians in diagnosing medical conditions, injuries, and diseases. X-Ray Technologists ensure patient safety by following radiation safety protocols and positioning patients accurately for imaging. They play a crucial role in providing accurate diagnostic information to help healthcare professionals make informed decisions about patient care and treatment.
              </JobDescription>
            </JobCard>
          </JobsGrid>
        </ContentSection>

        <ContactBanner>
          <ContactText>DO YOU HAVE A QUESTION?</ContactText>
          <Button to="tel:+15154462266">Call (515) 446-2266</Button>
        </ContactBanner>
      </PageContainer>
      </div>
    </div>
  );
};

export default Imaging;