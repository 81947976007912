// src/pages/LongTermCare.jsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackToHomeLink from '../components/BackToHomeLink';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  background-color: #28346c;
  color: white;
  padding: 4rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

const HeroTitle = styled.h1`
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-size: 2.5rem;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const HeroDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  color: white;
`;

const CTASection = styled.div`
  background-color: #f8f9fa;
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CTATitle = styled.h2`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.8rem;
`;

const CTADescription = styled.p`
  color: #54698d;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #28346c;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1e2756;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const StatsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
`;

const StatCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }
`;

// Remove unused styled component

const StatText = styled.p`
  color: #54698d;
  line-height: 1.6;
`;

const ContentSection = styled.div`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  color: #28346c;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  text-align: center;
`;

const JobsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
`;

const JobCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const JobTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const JobDescription = styled.p`
  color: #54698d;
  line-height: 1.6;
`;

const ContactBanner = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContactText = styled.h3`
  color: #28346c;
  margin: 0;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const LongTermCare = () => {

  useEffect(() => {
    document.title = "Long Term Care Nurse Jobs | Find LTC Nursing Opportunities with Capstone";
      
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Explore rewarding Long Term Care Nurse Jobs with Capstone. Connect with top healthcare facilities and build a fulfilling career in long-term care nursing');
    }
  }, []);

  return (
    <div>
       <div>
       <VisuallyHidden>Long Term Care Nurse Jobs – Advance Your Career in LTC Nursing</VisuallyHidden>
      <PageContainer>
      <BackToHomeLink />
        <HeroSection>
          <HeroTitle>Long-Term Care</HeroTitle>
          <HeroContent>
            <HeroDescription>
              At Capstone Health, we are dedicated to enhancing the quality of long-term care through our exceptional nursing staffing solutions. Our team of healthcare experts understands the unique demands of long-term care facilities, and we specialize in providing the right professionals to meet these needs.
            </HeroDescription>
            <HeroDescription>
              From experienced Long-Term Care RNs who bring their clinical expertise and compassionate care to the forefront, to skilled Licensed Practical Nurses (LPNs) and Licensed Vocational Nurses (LVNs) who provide essential support, and dedicated Certified Nursing Assistants (CNAs) who offer personalized attention and assistance – our nursing staff is meticulously selected to ensure the well-being and comfort of your residents.
            </HeroDescription>
            <HeroDescription>
              With an unwavering commitment to excellence, we recognize the pivotal role that each of these healthcare professionals plays in the lives of those entrusted to your care. Capstone Health is here to bridge the gap between your facility's requirements and the exceptional nursing talent that can elevate your long-term care services.
            </HeroDescription>
            <HeroDescription>
              Partner with us and experience the transformative power of Capstone Health's nursing staffing expertise in enhancing the lives of residents and the overall success of your long-term care facility.
            </HeroDescription>
          </HeroContent>
        </HeroSection>

        <CTASection>
          <CTATitle>Seeking Long Term Care Nursing Opportunities?</CTATitle>
          <CTADescription>
            Explore a wide range of Long Term Care nursing positions nationwide with Capstone Health.
            Embark on your nursing career journey today.
          </CTADescription>
          <Button to="/contact">Reach Us</Button>
        </CTASection>

        <SectionTitle>Facts about demand of Long Term Care (LTC) Nursing</SectionTitle>
        <StatsSection>
          <StatCard>
            <StatText>The demand for registered nurses (RNs) and licensed practical/vocational nurses (LPNs) in Long Term Care (LTC) settings will increase by 28% and 41%, respectively, from 2015 to 2030</StatText>
          </StatCard>
          <StatCard>
            <StatText>The demand for Long Term Care (LTC) in the USA is expected to grow significantly in the coming years, due to the aging of the population and the increasing prevalence of chronic conditions. The demand for home health care is projected to increase by 7.9% annually</StatText>
          </StatCard>
          <StatCard>
            <StatText>Projected shortage of 64,000 RNs and 28,000 LPNs in Long Term Care (LTC) settings by 2030</StatText>
          </StatCard>
        </StatsSection>

        <ContentSection>
          <JobsGrid>
            <JobCard>
              <JobTitle>Long-Term Care RN</JobTitle>
              <JobDescription>
                Long-Term Care Registered Nurses (RNs) are the cornerstone of resident care. With their specialized skills, they oversee the comprehensive well-being of residents in extended care facilities. Long-Term Care RNs administer medications, monitor health conditions, collaborate with interdisciplinary teams, and provide emotional support to residents and their families. Their compassionate dedication ensures a high standard of care, enhancing the quality of life for those in long-term care settings.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Licensed Practical Nurse (LPN)</JobTitle>
              <JobDescription>
                The role of a Long-Term Care Licensed Practical Nurse (LPN) is essential in providing fundamental medical care. This includes tasks such as administering medication, changing bandages, and gathering specimens. Working within a long-term care setting, the LPN delivers nursing care while under the supervision of a registered nurse or medical authority. The Long-Term Care LPN is responsible for observing and communicating any shifts in the patient's condition to the supervisor. Assisting with bathing, feeding, and dressing, they ensure patients' health, comfort, and safety. Education-wise, a high school diploma and graduation from an accredited LPN program are typically required, along with state licensure. Specialized certification and/or licensing might be needed for specific roles within this position. The specific years of experience required can vary, and it's recommended to hold a certification within the field.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Licensed Vocational Nurse (LVN)</JobTitle>
              <JobDescription>
                A Licensed Vocational Nurse (LVN) operates within hospitals, nursing homes, or medical care facilities, usually under the supervision of a registered nurse (RN) or physician. Their duties encompass:

                Being the Initial Care Provider: LVNs are often the first point of contact for patients, delivering essential care and comfort.

                Vital Signs Monitoring: They proficiently measure and observe vital signs such as blood pressure, pulse rate, respiratory rate, and temperature.

                Providing Comfort: LVNs offer compassionate care, ensuring patient comfort through bathing, dressing, and maintaining warmth.

                Medical Procedures: They carry out tasks like changing bandages, catheter insertion, and administering injections.

                Preparing for Treatment: LVNs prepare patients for treatments and surgical procedures, including drawing blood.

                Patient Interaction: They discuss health concerns with patients and report condition updates to doctors and registered nurses.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Certified Nursing Assistant (CNA)</JobTitle>
              <JobDescription>
                A Certified Nursing Assistant (CNA), also known as a nurse aide, patient care technician, or nursing assistant, is a pivotal figure in healthcare. Operating under the supervision of a Registered Nurse (RN) or Licensed Practical Nurse (LPN), CNAs provide fundamental patient care. From dressing and bathing to monitoring vital signs, they play an essential role in patient well-being.

                These healthcare heroes form the bedrock of the American healthcare system. With a streamlined certification process, becoming a CNA often serves as the entry point for aspiring nursing professionals. This guide delves into the CNA role, remuneration, and the path to CNA certification. Read on to embark on your journey toward a fulfilling nursing assistant career.
              </JobDescription>
            </JobCard>
          </JobsGrid>
        </ContentSection>

        <ContactBanner>
          <ContactText>DO YOU HAVE A QUESTION?</ContactText>
          <Button to="tel:+15154462266">Call (515) 446-2266</Button>
        </ContactBanner>
      </PageContainer>
      </div>
    </div>
  );
};

export default LongTermCare;