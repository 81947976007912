// src/pages/PrivacyPolicy.jsx
import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const PageTitle = styled.h1`
  margin-bottom: 2rem;
  color: #2c3e50;
`;

const ContentSection = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  color: #54698d;
  line-height: 1.6;
`;

const LastUpdated = styled.div`
  margin-bottom: 2rem;
  font-style: italic;
  color: #7f8c8d;
  font-size: 0.9rem;
`;

const List = styled.ul`
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
  color: #54698d;
  line-height: 1.6;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const PrivacyPolicy = () => {
  return (
    <div>
      <PageContainer>
        <PageTitle>Privacy Policy</PageTitle>
        <LastUpdated>Last Updated: March 1, 2025</LastUpdated>

        <ContentSection>
          <Paragraph>
            Capstone Health is committed to protecting your privacy. Please read this privacy policy carefully before using our website.
          </Paragraph>
          
          <Paragraph>
            By accessing and using the Capstone Health website, you acknowledge and agree that you have fully read, understood, and consent to be bound by the terms, conditions, and policies outlined herein.
          </Paragraph>
          
          <SectionTitle>Information Sharing Policy</SectionTitle>
          <Paragraph>
            No mobile information will be shared with third parties or affiliates for marketing or promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
          </Paragraph>
          
          <SectionTitle>Your Account Responsibilities</SectionTitle>
          <Paragraph>
            As a user of the Capstone Health site, you have several important responsibilities:
          </Paragraph>
          <List>
            <ListItem>Maintaining the confidentiality of your account and password</ListItem>
            <ListItem>Restricting access to your devices</ListItem>
            <ListItem>Accepting responsibility for all activities that occur under your account or password</ListItem>
          </List>
          <Paragraph>
            Capstone Health reserves the right to refuse service, terminate accounts, edit content, or cancel orders at its sole discretion.
          </Paragraph>
          
          <SectionTitle>Prohibited Conduct</SectionTitle>
          <Paragraph>
            Illegal or abusive usage of our website is strictly prohibited. You must comply with all local and international laws regarding online conduct and content. The following behaviors are not permitted:
          </Paragraph>
          <List>
            <ListItem>Engaging in harassing, threatening, impersonating, or intimidating behavior</ListItem>
            <ListItem>Using Capstone Health services for illegal or unauthorized purposes</ListItem>
            <ListItem>Attempting to circumvent website security measures</ListItem>
            <ListItem>Distributing malicious content or malware</ListItem>
          </List>
          
          <SectionTitle>Copyright Protection</SectionTitle>
          <Paragraph>
            All content on this site, including text, graphics, logos, images, audio clips, and software, is the property of Capstone Health and protected by U.S. and international copyright laws. Unauthorized use, modification, or reproduction of the site content is strictly prohibited.
          </Paragraph>
          
          <SectionTitle>Electronic Communication Consent</SectionTitle>
          <Paragraph>
            By using the Capstone Health website, you consent to receive electronic communications from us. We will communicate with you via email or by posting notices on the site. You agree that all electronic communications fulfill any legal requirement that such communications be in writing.
          </Paragraph>
          
          <SectionTitle>Trademarks</SectionTitle>
          <Paragraph>
            The Capstone Health name, logo, and other brand elements are trademarks or registered trademarks of Capstone Health. They may not be used without our express written consent. Unauthorized use may result in infringement penalties.
          </Paragraph>
          
          <SectionTitle>License and Site Access</SectionTitle>
          <Paragraph>
            Capstone Health grants you a limited license to access and personally use this site, but not to download (other than page caching) or modify it, or any portion of it, without our express written consent. This license does not include any resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; or any derivative use of this site or its contents.
          </Paragraph>
          
          <SectionTitle>Risk of Loss</SectionTitle>
          <Paragraph>
            All items purchased from Capstone Health are made pursuant to a shipment contract. This means the risk of loss and title for such items pass to you upon delivery to the carrier.
          </Paragraph>
          
          <SectionTitle>Disclaimer of Warranties and Limitation of Liability</SectionTitle>
          <Paragraph>
            This site and all information, content, materials, products, and services are provided on an "as is" and "as available" basis unless otherwise specified. Capstone Health makes no representations or warranties of any kind, express or implied, as to the operation of this site or the information, content, materials, or products included on this site. You expressly agree that your use of this site is at your sole risk.
          </Paragraph>
          
          <SectionTitle>Applicable Law</SectionTitle>
          <Paragraph>
            By visiting the Capstone Health website, you agree that the laws of the state of Iowa, without regard to principles of conflict of laws, will govern these Conditions of Use and any dispute that might arise between you and Capstone Health.
          </Paragraph>
          
          <SectionTitle>Disputes</SectionTitle>
          <Paragraph>
            Any dispute relating in any way to your visit to the Capstone Health website or to products or services sold or distributed by Capstone Health will be adjudicated in any state or federal court in Iowa, and you consent to exclusive jurisdiction and venue in such courts.
          </Paragraph>
          
          <SectionTitle>Privacy Rights</SectionTitle>
          <Paragraph>
            Capstone Health respects the privacy of our online visitors and has adopted a comprehensive Online Privacy Policy that guides how we collect, store, and use the information you provide. This policy applies only to sites maintained by Capstone Health, not to websites maintained by other companies or organizations to which we link.
          </Paragraph>
          
          <SectionTitle>Contact Us</SectionTitle>
          <Paragraph>
            For any questions or concerns regarding these terms or your use of the website, please contact Capstone Health at:
          </Paragraph>
          <Paragraph>
            Capstone Health<br />
            1540 High St, Ste 101<br />
            Des Moines, IA 50309<br />
            Email: contact@capstone.net<br />
            Phone: (515) 446-2266<br />
            Fax: (515) 528-7778
          </Paragraph>
        </ContentSection>
      </PageContainer>
    </div>
  );
};

export default PrivacyPolicy;