// src/data/reviewsData.js

export const reviews = [
    {
      id: 1,
      name: "Abraham Ikeji",
      rating: 5,
      relativeDate: "3 weeks ago",
      textReview: "Assistance and support during my job experience was excellent. Some others could have given up on me, but they did not even with other many employees and prospects they had. A large part of my appreciation goes to my recruiter (her patience was admirable) and the onboarding team for being understanding. Overall it was an experience I will always remember. Thanks Capstone Health~ Abraham",
      imagePath: "/healthcare-review-abraham-nurse.png"
    },
    {
      id: 2,
      name: "H. Nicole",
      rating: 5,
      relativeDate: "3 weeks ago",
      textReview: "Great company to work for & always willing to support",
      imagePath: "/healthcare-review-nicole-staffing.png"
    },
    {
      id: 3,
      name: "Forrest Leirer",
      rating: 5,
      relativeDate: "a month ago",
      textReview: "I have had a great experience with this company so far. The communication is fast and efficient and I haven't had any problems that were not fixed promptly.",
      imagePath: "/healthcare-review-forrest-testimonial.png"
    },
    {
      id: 4,
      name: "Roberta Mohler",
      rating: 5,
      relativeDate: "a month ago",
      textReview: "I love working with Capstone Health! I have the best recruiter and always there for me. I feel so blessed.",
      imagePath: "/healthcare-review-roberta-testimonial.png"
    },
    {
      id: 5,
      name: "Miriam Calisaya Vargas",
      rating: 5,
      relativeDate: "a month ago",
      textReview: "",
      imagePath: "/healthcare-review-miriam-testimonial.png"
    },
    {
      id: 6,
      name: "James Pinet",
      rating: 5,
      relativeDate: "2 months ago",
      textReview: "Great company with plenty of support",
      imagePath: "/healthcare-review-james-testimonial.png"
    },
    {
      id: 7,
      name: "Ada Clark",
      rating: 5,
      relativeDate: "53 months ago",
      textReview: "My representative has opened many great doors for me and my future.... I truly appreciate everything done to make it worth my while. Thank you so much.",
      imagePath: "/healthcare-review-ada-testimonial.png"
    },
    {
      id: 8,
      name: "Ray Collins",
      rating: 5,
      relativeDate: "10 months ago",
      textReview: "Very professional, fast response times through the whole process. I can't wait to continue to work with them.",
      imagePath: "/healthcare-review-ray-testimonial.png"
    },
    {
      id: 9,
      name: "Sarah Raner",
      rating: 5,
      relativeDate: "11 months ago",
      textReview: "Super friendly and easily available to you.",
      imagePath: "/healthcare-review-sarah-testimonial.png"
    },
    {
      id: 10,
      name: "Advertus Sloh",
      rating: 5,
      relativeDate: "a year ago",
      textReview: "Good work environment where leaders put employees interest above their personal interests.",
      imagePath: "/healthcare-review-advertus-testimonial.png"
    },
    {
      id: 11,
      name: "Les Neuhaus",
      rating: 5,
      relativeDate: "a year ago",
      textReview: "",
      imagePath: "/healthcare-review-les-testimonial.png"
    },
    {
      id: 12,
      name: "Kevin Taylor Jr.",
      rating: 5,
      relativeDate: "a year ago",
      textReview: "Like any new job onboarding is a lot but the staff makes it easy. They keep you informed, up to date and very patient as well. They want you to succeed. Very easy to work with!",
      imagePath: "/healthcare-review-kevin-testimonial.png"
    }
  ];

  
  
  
  export default reviews;