// src/pages/WhyCapstone.jsx
import React, { useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackToHomeLink from '../components/BackToHomeLink';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  background-color: #28346c;
  color: white;
  padding: 4rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: center;
`;

const HeroTitle = styled.h1`
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-size: 2.5rem;
`;

const HeroDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: white;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const ContentSection = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: #28346c;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  text-align: center;
  border-bottom: 2px solid #28346c;
  padding-bottom: 0.5rem;
`;

const SectionSubtitle = styled.h3`
  color: #2c3e50;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const Paragraph = styled.p`
  color: #54698d;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const BenefitsList = styled.ul`
  color: #54698d;
  line-height: 1.6;
  margin-left: 2rem;
  margin-bottom: 1.5rem;
`;

const BenefitItem = styled.li`
  margin-bottom: 0.75rem;
`;

const CTASection = styled.div`
  background-color: #f8f9fa;
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CTATitle = styled.h2`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.8rem;
`;

const CTADescription = styled.p`
  color: #54698d;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #28346c;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1e2756;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ReferralHighlight = styled.div`
  background-color: #f0f4f8;
  border-left: 4px solid #28346c;
  padding: 1.5rem;
  margin: 2rem 0;
  border-radius: 4px;
`;

// Add this styled component with your other styled components
const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const WhyCapstone = () => {

    useEffect(() => {
      document.title = "Agency Nurse & Travel Therapy Positions | Certified Surgical Tech Jobs - Capstone Health";
      
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', 'Explore rewarding agency nurse jobs, travel therapy positions, and certified surgical tech jobs with Capstone. Advance your healthcare career with top opportunities today!');
      }
    }, []);

  return (
    <div>
      <VisuallyHidden>Why Choose Capstone? Trusted Healthcare Staffing Solutions</VisuallyHidden>
    <div>
      <PageContainer>
        <BackToHomeLink />
        
        <HeroSection>
          <HeroTitle>Elevate Your Career with Capstone Health</HeroTitle>
          <HeroDescription>
            Your healthcare journey gains a dynamic edge with our innovative platform. 
            We connect talented professionals with unparalleled opportunities, 
            transforming careers and fostering success.
          </HeroDescription>
        </HeroSection>

        <ContentSection>
          <SectionTitle>Our Unique Value Proposition</SectionTitle>
          
          <SectionSubtitle>Unlock Boundless Opportunities</SectionSubtitle>
          <Paragraph>
            As a member of Capstone Health, you gain access to a thriving professional network that stretches far and wide. 
            Our strong connections with industry-leading companies open doors to a multitude of exciting prospects, 
            providing you with more career avenues than ever before.
          </Paragraph>
          
          <SectionSubtitle>Fostering Meaningful Relationships</SectionSubtitle>
          <Paragraph>
            Our specialty lies in nurturing connections that lead to the right opportunities. 
            At Capstone Health, we proactively engage with top-tier talent like you. 
            Whether you seek full-time roles or part-time positions, let's connect to explore the possibilities together.
          </Paragraph>
          
          <SectionSubtitle>Collaborative Guidance</SectionSubtitle>
          <Paragraph>
            Your journey should be smooth, and that's why we go the extra mile to simplify your path. 
            From day one, we pair you with an adept Talent Recruiter who acts as your personal advocate. 
            They'll guide you through every phase of the job process, ensuring a seamless experience.
          </Paragraph>
          
          <ReferralHighlight>
            <SectionSubtitle>Refer and Earn</SectionSubtitle>
            <Paragraph>
              Do you know remarkable individuals? Illuminate their path by introducing them to our innovative recruitment agency.
              For every successful connection you make, receive up to $1000 as a token of our gratitude.
              It's a win-win – you empower others while adding radiance to your journey with Capstone Health.
            </Paragraph>
          </ReferralHighlight>
        </ContentSection>

        <ContentSection>
          <SectionTitle>Empowering Benefits</SectionTitle>
          <Paragraph>
            We're dedicated to supporting your success every step of the way. 
            Our comprehensive benefits package is designed to provide you with security, support, and opportunities for growth.
          </Paragraph>
          
          <BenefitsList>
            <BenefitItem>
              <strong>Healthcare Coverage:</strong> Enjoy the peace of mind that comes with top-tier medical and dental benefits, 
              the same ones we offer our own team.
            </BenefitItem>
            <BenefitItem>
              <strong>Paid Time Off:</strong> Strike the right balance between work and play with our vacation and holiday pay options.
            </BenefitItem>
            <BenefitItem>
              <strong>Financial Planning:</strong> Secure your future with our 401(k) plan, designed to help you achieve your long-term goals.
            </BenefitItem>
            <BenefitItem>
              <strong>Competitive Compensation:</strong> Your hard work deserves fair compensation. We handle the paperwork and taxes 
              so you can focus on excelling.
            </BenefitItem>
            <BenefitItem>
              <strong>Personalized Job Matching:</strong> Your dedicated Talent Representative understands your skills, 
              aspirations, and experiences, ensuring the perfect match.
            </BenefitItem>
            <BenefitItem>
              <strong>Referral Program:</strong> Sharing is rewarding – refer a fellow talent and earn up to $1000 when they 
              find success through Capstone Health.
            </BenefitItem>
          </BenefitsList>
        </ContentSection>

        <CTASection>
          <CTATitle>Ready to Transform Your Career?</CTATitle>
          <CTADescription>
            Join Capstone Health and experience a partnership that goes beyond traditional staffing. 
            We're committed to your professional growth, personal development, and long-term success.
          </CTADescription>
          <Button to="/contact">Get Started</Button>
        </CTASection>
      </PageContainer>
      </div>
    </div>
  );
};

export default WhyCapstone;