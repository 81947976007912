// src/pages/ApplicationSuccess.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import '../CapstoneTheme.css';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const SuccessCard = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 2rem;
`;

const CardHeader = styled.div`
  background: linear-gradient(to right, #04844b, #36b37e);
  color: white;
  padding: 1.5rem;
  text-align: center;
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &::after {
    content: '✓';
    display: inline-block;
    margin-left: 8px;
    font-weight: bold;
  }
`;

const CardBody = styled.div`
  padding: 2.5rem 1.5rem;
  text-align: center;
`;

const Message = styled.p`
  font-size: 1.1rem;
  color: #54698d;
  margin-bottom: 2.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const PrimaryButton = styled(Link)`
  background-color: #0070d2;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
  display: inline-block;

  &:hover {
    background-color: #005fb2;
    color: white;
    text-decoration: none;
  }
`;

const HelpText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: #54698d;
  margin-top: 1.5rem;
  
  &::before {
    content: '?';
    display: inline-block;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 50%;
    background: #54698d;
    color: white;
    font-size: 12px;
  }
  
  a {
    color: #0070d2;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ApplicationSuccess = () => {
  return (
    <div>
     
      <PageContainer>
        <SuccessCard>
          <CardHeader>
            <CardTitle>Upload Successful</CardTitle>
          </CardHeader>
          
          <CardBody>
            <Message>
              Your application has been received and is being processed. Our team will review your information and contact you regarding next steps.
            </Message>
            
            <ButtonContainer>
              <PrimaryButton to="/jobs">Browse More Jobs</PrimaryButton>
              <PrimaryButton to="/">Return to Home</PrimaryButton>
            </ButtonContainer>
          </CardBody>
        </SuccessCard>
        
        <HelpText>
          Need help? Contact <a href="mailto:contact@capstone.net">contact@capstone.net</a>
        </HelpText>
      </PageContainer>
    </div>
  );
};

export default ApplicationSuccess;