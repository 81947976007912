// src/pages/TermsAndConditions.jsx
import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const PageTitle = styled.h1`
  margin-bottom: 2rem;
  color: #2c3e50;
`;

const ContentSection = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  color: #54698d;
  line-height: 1.6;
`;

const LastUpdated = styled.div`
  margin-bottom: 2rem;
  font-style: italic;
  color: #7f8c8d;
  font-size: 0.9rem;
`;

const List = styled.ul`
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
  color: #54698d;
  line-height: 1.6;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const TermsAndConditions = () => {
  return (
    <div>
      <PageContainer>
        <PageTitle>Terms and Conditions</PageTitle>
        <LastUpdated>Last Updated: March 1, 2025</LastUpdated>

        <ContentSection>
          <Paragraph>
            Welcome to the Capstone Health website ("Site"). By accessing or using the Site (https://capstone.net/), you agree to comply with the following Terms and Conditions ("Terms"). Please read these Terms carefully. If you do not agree to any part of these Terms, you must not use the Site.
          </Paragraph>
          
          <SectionTitle>1. Acceptance of Terms</SectionTitle>
          <Paragraph>
            By using this Site, you agree to these Terms and our Privacy Policy, which are incorporated herein by reference. Your use of the Site signifies your acceptance of these Terms. If you do not agree, you must stop using the Site immediately.
          </Paragraph>
          
          <SectionTitle>2. Eligibility</SectionTitle>
          <Paragraph>
            You must be at least 18 years old or the age of majority in your jurisdiction to access or use the Site. By using this Site, you confirm that you meet these eligibility requirements.
          </Paragraph>
          
          <SectionTitle>3. Use of the Website</SectionTitle>
          <Paragraph>
            Capstone Health grants you a limited, non-exclusive, non-transferable, and revocable license to use the Site for personal, non-commercial purposes, including job seeking and hiring services.
          </Paragraph>
          <Paragraph>
            You agree not to:
          </Paragraph>
          <List>
            <ListItem>Use the Site for any unlawful or prohibited purposes.</ListItem>
            <ListItem>Violate any applicable local, state, national, or international laws or regulations.</ListItem>
            <ListItem>Misrepresent your identity, qualifications, or affiliations.</ListItem>
            <ListItem>Post, upload, or share any harmful, obscene, misleading, or inappropriate content.</ListItem>
          </List>
          
          <SectionTitle>4. Intellectual Property</SectionTitle>
          <Paragraph>
            All content on this Site, including text, graphics, logos, images, and software, is the property of Capstone Health or its licensors and is protected by U.S. and international copyright and trademark laws.
          </Paragraph>
          <Paragraph>
            You may not reproduce, distribute, modify, or create derivative works without express written permission from Capstone Health.
          </Paragraph>
          
          <SectionTitle>5. User Conduct</SectionTitle>
          <Paragraph>
            You agree to use the Site responsibly and in accordance with the law. You must refrain from:
          </Paragraph>
          <List>
            <ListItem>Harassing, threatening, impersonating, or intimidating other users.</ListItem>
            <ListItem>Uploading malicious software, spam, or content that disrupts the Site.</ListItem>
            <ListItem>Engaging in fraudulent, misleading, or unethical behavior.</ListItem>
          </List>
          
          <SectionTitle>6. Job Postings and Applications</SectionTitle>
          <Paragraph>
            Employers must ensure job postings comply with all applicable employment laws, including anti-discrimination laws.
          </Paragraph>
          <Paragraph>
            Job seekers are responsible for ensuring their submitted information is accurate, truthful, and complete.
          </Paragraph>
          
          <SectionTitle>7. Privacy and Data Protection</SectionTitle>
          <Paragraph>
            By using this Site, you consent to the collection, use, and storage of your personal data in accordance with our Privacy Policy.
          </Paragraph>
          <Paragraph>
            Capstone Health complies with applicable data privacy regulations, including the California Consumer Privacy Act (CCPA) and, where applicable, the General Data Protection Regulation (GDPR).
          </Paragraph>
          
          <SectionTitle>8. Accessibility</SectionTitle>
          <Paragraph>
            Capstone Health is committed to ensuring this Site is accessible to users with disabilities in compliance with the Americans with Disabilities Act (ADA). If you encounter accessibility issues, please contact us at contact@capstone.net
          </Paragraph>
          
          <SectionTitle>9. Cookies and Tracking</SectionTitle>
          <Paragraph>
            The Site uses cookies and similar technologies to enhance your user experience and monitor Site performance. By using the Site, you agree to our use of cookies as outlined in our Privacy Policy.
          </Paragraph>
          
          <SectionTitle>10. Third-Party Links</SectionTitle>
          <Paragraph>
            The Site may include links to third-party websites for convenience. Capstone Health is not responsible for the content, privacy practices, or terms of these external sites. Use of these links is at your own risk.
          </Paragraph>
          
          <SectionTitle>11. Limitation of Liability</SectionTitle>
          <Paragraph>
            Capstone Health is not liable for:
          </Paragraph>
          <List>
            <ListItem>Any loss or damages resulting from your use of the Site.</ListItem>
            <ListItem>Indirect, incidental, or consequential damages, including data breaches or unauthorized access due to negligence.</ListItem>
            <ListItem>Any reliance on information or services provided on this Site.</ListItem>
          </List>
          
          <SectionTitle>12. Indemnification</SectionTitle>
          <Paragraph>
            You agree to indemnify and hold Capstone Health, its affiliates, employees, and partners harmless from any claims, damages, or expenses resulting from:
          </Paragraph>
          <List>
            <ListItem>Your violation of these Terms.</ListItem>
            <ListItem>Your misuse of the Site or its content.</ListItem>
            <ListItem>Your breach of applicable laws or regulations.</ListItem>
          </List>
          
          <SectionTitle>13. Termination</SectionTitle>
          <Paragraph>
            Capstone Health reserves the right to terminate or suspend your access to the Site for violations of these Terms or misuse of the platform, with or without notice.
          </Paragraph>
          
          <SectionTitle>14. Modifications to Terms</SectionTitle>
          <Paragraph>
            Capstone Health reserves the right to modify these Terms at any time. Your continued use of the Site constitutes acceptance of the updated Terms. The most current version will always be available on this page.
          </Paragraph>
          
          <SectionTitle>15. Governing Law</SectionTitle>
          <Paragraph>
            These Terms are governed by and construed under the laws of the United States and the state of Iowa, without regard to conflict of law principles. All disputes will be resolved in the courts located in Iowa.
          </Paragraph>
          
          <SectionTitle>16. Severability</SectionTitle>
          <Paragraph>
            If any provision of these Terms is found to be unlawful or unenforceable, the remaining provisions will remain in full force and effect.
          </Paragraph>
          
          <SectionTitle>17. Contact Information</SectionTitle>
          <Paragraph>
            For any questions or concerns regarding these Terms or the use of the Site, please contact us:
          </Paragraph>
          <Paragraph>
            Capstone Health<br />
            Email: contact@capstone.net<br />
            Phone: (515) 446-2266<br />
            Fax: (515) 528-7778<br />
            Address: 1540 High St, Ste 101, Des Moines, IA 50309
          </Paragraph>
        </ContentSection>
      </PageContainer>
    </div>
  );
};

export default TermsAndConditions;