// src/pages/TravelNursing.jsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackToHomeLink from '../components/BackToHomeLink';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  background-color: #28346c;
  color: white;
  padding: 4rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

const HeroTitle = styled.h1`
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-size: 2.5rem;
`;

const HeroDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: white;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const CTASection = styled.div`
  background-color: #f8f9fa;
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CTATitle = styled.h2`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.8rem;
`;

const CTADescription = styled.p`
  color: #54698d;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #28346c;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1e2756;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const StatSection = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Remove unused styled component

const StatText = styled.p`
  color: #54698d;
  font-size: 1.2rem;
  margin-bottom: 0;
`;

const ContentSection = styled.div`
  margin-bottom: 3rem;
`;

// Remove unused styled component

const ContentCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ContentTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const ContentText = styled.p`
  color: #54698d;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const BenefitsList = styled.ul`
  color: #54698d;
  line-height: 1.6;
  margin-left: 2rem;
  margin-bottom: 1.5rem;
`;

const BenefitItem = styled.li`
  margin-bottom: 0.5rem;
`;

const ContactBanner = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContactText = styled.h3`
  color: #28346c;
  margin: 0;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const TravelNursing = () => {

  useEffect(() => {
    document.title = "Travel Nursing Jobs | Explore Exciting Opportunities with Capstone";
      
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Find rewarding travel nursing jobs with Capstone. Explore top healthcare facilities, enjoy competitive benefits, and advance your nursing career today!');
    }
  }, []);

  return (
    <div>
      <VisuallyHidden>Travel Nursing Jobs – Explore New Opportunities with Capstone</VisuallyHidden>
    <div>
      <PageContainer>
      <BackToHomeLink />
        <HeroSection>
          <HeroTitle>Travel Nursing with Capstone Health</HeroTitle>
          <HeroDescription>
            Unlock Incredible Adventures and Career Growth with Capstone Health Travel Nursing
          </HeroDescription>
          <HeroDescription>
            Travel nursing isn't just a job; it's a rewarding journey that lets you explore the nation, elevate your clinical expertise, and truly make a difference in healthcare. If that sounds enticing, it's time to experience the world of travel nursing with Capstone Health.
          </HeroDescription>
          <HeroDescription>
            As a travel nurse, you become the author of your own adventure. Are you drawn to the bright lights of iconic cities like New York or the vibrant culture of New Orleans? Perhaps you're more at home in the great outdoors, ready to tackle the Colorado River's rapids or hike among the towering Redwoods of Big Sur. And for those seeking relaxation, picture yourself unwinding on the pristine beaches of Miami.
          </HeroDescription>
        </HeroSection>

        <CTASection>
          <CTATitle>Seeking Traveling Nursing Opportunities?</CTATitle>
          <CTADescription>
            Explore a wide range of travelling nursing positions nationwide with Capstone Health.
            Embark on your nursing career journey today.
          </CTADescription>
          <Button to="/contact">Contact Us</Button>
        </CTASection>

        <StatSection>
          <StatText>Over 1.7 million traveling nurses currently employed in the United States, and 84.1% of them are women</StatText>
        </StatSection>

        <ContentSection>
          <ContentCard>
            <ContentTitle>Start Your Capstone Health Travel Nursing Journey Today</ContentTitle>
            <ContentText>
              Embark on an incredible adventure, both personally and professionally, by choosing Capstone Health as your travel nursing partner. Get ready to explore, grow, and make a lasting impact on healthcare.
            </ContentText>
            <ContentText>
              Contact Capstone Health now to dive into the world of travel nursing with one of the leading Travel Nursing Agencies. Your journey starts here.
            </ContentText>
          </ContentCard>

          <ContentCard>
            <ContentTitle>Empowering Benefits:</ContentTitle>
            <ContentText>
              We're dedicated to supporting your success every step of the way. Our comprehensive benefits package includes:
            </ContentText>
            <BenefitsList>
              <BenefitItem>
                <strong>Healthcare Coverage:</strong> Enjoy the peace of mind that comes with top-tier medical and dental benefits, the same ones we offer our own team.
              </BenefitItem>
              <BenefitItem>
                <strong>Paid Time Off:</strong> Strike the right balance between work and play with our vacation and holiday pay options.
              </BenefitItem>
              <BenefitItem>
                <strong>Financial Planning:</strong> Secure your future with our 401(k) plan, designed to help you achieve your long-term goals.
              </BenefitItem>
              <BenefitItem>
                <strong>Competitive Compensation:</strong> Your hard work deserves fair compensation. We handle the paperwork and taxes so you can focus on excelling.
              </BenefitItem>
              <BenefitItem>
                <strong>Personalized Job Matching:</strong> Your dedicated Talent Representative understands your skills, aspirations, and experiences, ensuring the perfect match.
              </BenefitItem>
              <BenefitItem>
                <strong>Referral Program:</strong> Sharing is rewarding – refer a fellow talent and earn up to $300 when they find success through Capstone Health.
              </BenefitItem>
            </BenefitsList>
            <ContentText>
              Elevate your career with Capstone Health, where your growth and success are at the heart of our mission.
            </ContentText>
          </ContentCard>
        </ContentSection>

        <ContactBanner>
          <ContactText>DO YOU HAVE A QUESTION?</ContactText>
          <Button to="tel:+15154462266">Call (515) 446-2266</Button>
        </ContactBanner>
      </PageContainer>
      </div>
    </div>
  );
};

export default TravelNursing;