import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

// Animation for the gradient background
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Keyframe for the letter highlight animation
const letterHighlight = keyframes`
  0% {
    color: white;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  11.1%, 44.4% {
    color: #ff69b4; /* Pink color */
    text-shadow: 0 0 8px rgba(255, 105, 180, 0.5);
  }
  50%, 100% {
    color: white;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

// Styled component for each letter
const AnimatedLetter = styled.span`
  display: inline-block;
  animation: ${letterHighlight} 9s infinite;
  animation-delay: calc(var(--index) * (1s / var(--total-letters)));
`;

// Animated gradient
const HeroSection = styled.section`
  background: linear-gradient(
    135deg,
    #28346c 0%,
    #2c397a 25%,
    #232c5c 50%,
    #2c397a 75%,
    #28346c 100%
  );
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  position: relative;
  overflow: visible; /* Changed from 'hidden' to 'visible' to allow dropdowns to extend */
  z-index: 15; /* Add z-index */
  
  @media (max-width: 768px) {
    padding: 4rem 1.5rem;
  }
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 20; /* Increased z-index */
`;

// Title with responsive styling
const HeroTitle = styled.h1`
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: white;
  display: inline-block;
  position: relative;
  text-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    font-size: 2.2rem;
    line-height: 1.3;
    text-align: center;
    width: 100%;
    transform: scale(1.25);
    margin-bottom: 2rem;
  }
`;

// Styled components for each part of the title on mobile
const TitleFirstLine = styled.div`
  @media (max-width: 768px) {
    display: block;
  }
`;

const TitleSecondLine = styled.div`
  @media (max-width: 768px) {
    display: block;
  }
`;

const TitleThirdLine = styled.div`
  @media (max-width: 768px) {
    display: block;
  }
`;

// Desktop container
const DesktopTitleContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

// Mobile container
const MobileTitleContainer = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.3rem;
  line-height: 1.5;
  margin-bottom: 2.5rem;
  color: rgba(255, 255, 255, 0.95);
  position: relative;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
`;

// Subtle background pattern
const BackgroundPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(rgba(255, 255, 255, 0.15) 2px, transparent 2px);
  background-size: 30px 30px;
  opacity: 0.2;
  pointer-events: none;
`;

// Filter Bar styles
const FilterBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 20; /* Increased z-index */
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const FilterItem = styled.div`
  position: relative;
  flex: 1;
  z-index: 20; /* Add z-index to ensure proper stacking context */
  
  svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    z-index: 1;
  }
`;

const FilterLabel = styled.div`
  text-align: left;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: white;
`;

const FilterInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  color: #2c3e50;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  
  &:focus {
    outline: none;
    box-shadow: 0 4px 12px rgba(255, 92, 184, 0.2), 0 0 0 2px rgba(255, 92, 184, 0.1);
    transform: translateY(-1px);
  }
`;

const ChevronDown = styled.span`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 0.8rem;
  color: #666;
`;

// OVERLAY AND SCROLLABLE FILTERS
const Overlay = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
`;

// FilterOptions to match JobListings style
const FilterOptions = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  max-width: 350px;
  width: 90%;
  max-height: 70vh;
  border-radius: 4px;
  z-index: 100; /* Above overlay */
  padding: 1rem;
  overflow-y: auto;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 4px;
  }
`;

// Desktop dropdown options styling - matches JobListings style
const DesktopFilterOptions = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  background: white;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Increased z-index to ensure it stays on top */
  margin-top: 5px;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

// CloseButton to follow user on mobile
const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  color: #28346c;
  font-size: 1.25rem;
  cursor: pointer;
  
  @media (max-width: 768px) {
    position: fixed;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    
    &:hover {
      background-color: rgba(40, 52, 108, 0.1);
    }
  }
`;

// DropdownOption to match JobListings FilterOption with checkbox
const FilterOption = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #2c3e50;
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: #f5f5f5;
    color: #28346c;
  }
  
  @media (max-width: 768px) {
    padding: 12px 8px; /* Larger touch target for mobile */
    font-size: 1rem;
  }
`;

// New FilterCheckbox to match JobListings
const FilterCheckbox = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
  
  @media (max-width: 768px) {
    transform: scale(1.2); /* Larger checkbox on mobile */
    margin-right: 0.75rem;
  }
`;

const NoResults = styled.div`
  padding: 0.6rem 1rem;
  color: #7f8c8d;
  font-style: italic;
`;

// Search button with hover effect
const SearchButton = styled.button`
  background: linear-gradient(135deg, #28346c, #3a4785);
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  align-self: flex-end;
  margin-top: .35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
    transition: all 0.6s ease;
  }
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    background: linear-gradient(135deg, #3a4785, #28346c);
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(40,52,108,0.3);
  }
  
  &:hover::before {
    left: 100%;
  }
  
  &:active {
    transform: translateY(-1px);
  }
  
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

// CTA button
const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #ffffff, #f0f2f8);
  color: #28346c;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(1.25);
  margin-top: 1.5rem;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(40, 52, 108, 0.1);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(40, 52, 108, 0.05), transparent);
    transition: all 0.6s ease;
  }
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    background: linear-gradient(135deg, #f0f2f8, #ffffff);
    color: #3a4785;
    transform: scale(1.25) translateY(-2px);
    box-shadow: 0 6px 15px rgba(40, 52, 108, 0.25);
    border-color: rgba(40, 52, 108, 0.2);
  }
  
  &:hover::before {
    left: 100%;
  }
  
  &:active {
    transform: scale(1.25) translateY(-1px);
  }
  
  @media (max-width: 768px) {
    transform: scale(1.5);
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    
    &:hover, &:active {
      transform: scale(1.1) translateY(-2px);
    }
  }
`;

// Utility to check if mobile
const isMobileDevice = () => typeof window !== 'undefined' && window.innerWidth <= 768;

const EnhancedHeroSection = ({ 
  jobTitleSearch, 
  setJobTitleSearch, 
  stateSearch, 
  setStateSearch, 
  activeFilter, 
  setActiveFilter,
  handleSearch,
  loading,
  getFilteredOptions,
  getStateDisplayName
}) => {
  // For the animated letters in "Next Career"
  const nextCareerText = 'Next Career';
  const totalLetters = nextCareerText.length;

  // Track which filter we are showing in the overlay
  const [overlayVisible, setOverlayVisible] = useState(false);
  
  // Create refs for the dropdown containers
  const jobTitleFilterRef = useRef(null);
  const stateFilterRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (!isMobileDevice()) {
        setOverlayVisible(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Add click outside handler for desktop
  useEffect(() => {
    // Only add the listener if we're on desktop and a dropdown is active
    if (!isMobileDevice() && activeFilter !== null) {
      const handleClickOutside = (event) => {
        // Check if the click was outside both filter items
        if (
          (activeFilter === 'jobTitle' && 
           jobTitleFilterRef.current && 
           !jobTitleFilterRef.current.contains(event.target)) ||
          (activeFilter === 'state' && 
           stateFilterRef.current && 
           !stateFilterRef.current.contains(event.target))
        ) {
          setActiveFilter(null);
        }
      };
      
      // Add the event listener
      document.addEventListener('mousedown', handleClickOutside);
      
      // Clean up
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [activeFilter, setActiveFilter]);

  const handleFilterFocus = (filterName) => {
    if (!loading) {
      setActiveFilter(filterName);
      if (isMobileDevice()) {
        setOverlayVisible(true);
      }
    }
  };

  // Only run the blur logic on mobile
  const handleFilterBlur = () => {
    if (!isMobileDevice()) return;
    setTimeout(() => {
      setActiveFilter(null);
    }, 150);
  };

  const closeOverlay = () => {
    setActiveFilter(null);
    setOverlayVisible(false);
  };
  
  // Search handling to navigate with collapsed filters
  const handleSearchWithCollapsedFilters = () => {
    handleSearch();
    if (jobTitleSearch || stateSearch) {
      window.location.href = `/jobs?collapsed=true${jobTitleSearch ? `&title=${encodeURIComponent(jobTitleSearch)}` : ''}${stateSearch ? `&state=${encodeURIComponent(stateSearch)}` : ''}`;
    }
  };

  return (
    <HeroSection>
      <BackgroundPattern />

      <Overlay visible={overlayVisible} onClick={closeOverlay} />
      <FilterOptions
        visible={overlayVisible && (activeFilter === 'jobTitle' || activeFilter === 'state')}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={closeOverlay}>×</CloseButton>

        {activeFilter === 'jobTitle' && (
          <>
            {getFilteredOptions('jobTitle').length > 0 ? (
              getFilteredOptions('jobTitle').map((title) => (
                <FilterOption key={title}>
                  <FilterCheckbox 
                    type="checkbox"
                    checked={jobTitleSearch === title}
                    onChange={() => {
                      setJobTitleSearch(title === jobTitleSearch ? '' : title);
                      closeOverlay();
                    }}
                  />
                  {title}
                </FilterOption>
              ))
            ) : (
              <NoResults>No matching job titles</NoResults>
            )}
          </>
        )}

        {activeFilter === 'state' && (
          <>
            {getFilteredOptions('state').length > 0 ? (
              getFilteredOptions('state').map((abbr) => (
                <FilterOption key={abbr}>
                  <FilterCheckbox 
                    type="checkbox"
                    checked={stateSearch === abbr}
                    onChange={() => {
                      setStateSearch(abbr === stateSearch ? '' : abbr);
                      closeOverlay();
                    }}
                  />
                  {getStateDisplayName(abbr)}
                </FilterOption>
              ))
            ) : (
              <NoResults>No matching states</NoResults>
            )}
          </>
        )}
      </FilterOptions>

      <HeroContent>
        <DesktopTitleContainer>
          <HeroTitle>
            Find Your{' '}
            {nextCareerText.split('').map((letter, index) => (
              <AnimatedLetter
                key={index}
                style={{ 
                  '--index': index,
                  '--total-letters': totalLetters
                }}
              >
                {letter === ' ' ? '\u00A0' : letter}
              </AnimatedLetter>
            ))}{' '}
            Opportunity
          </HeroTitle>
        </DesktopTitleContainer>
        
        <MobileTitleContainer>
          <HeroTitle>
            <TitleFirstLine>
              Find Your
            </TitleFirstLine>
            <TitleSecondLine>
              {nextCareerText.split('').map((letter, index) => (
                <AnimatedLetter 
                  key={index}
                  style={{ 
                    '--index': index,
                    '--total-letters': totalLetters
                  }}
                >
                  {letter === ' ' ? '\u00A0' : letter}
                </AnimatedLetter>
              ))}
            </TitleSecondLine>
            <TitleThirdLine>
              Opportunity
            </TitleThirdLine>
          </HeroTitle>
        </MobileTitleContainer>
        
        <HeroSubtitle>
          Browse our available positions and join our team of talented professionals:
        </HeroSubtitle>
        
        <FilterBar>
          {/* JOB TITLE FILTER */}
          <FilterItem ref={jobTitleFilterRef}>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="16" height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M12 20h9"></path>
              <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
            </svg>
            <FilterLabel></FilterLabel>
            <FilterInput
              type="text"
              placeholder={loading ? "Loading options..." : "Your job title"}
              value={jobTitleSearch}
              onChange={(e) => {
                if (!isMobileDevice()) {
                  setJobTitleSearch(e.target.value);
                }
              }}
              readOnly={isMobileDevice()}  
              onClick={() => {
                if (isMobileDevice()) {
                  handleFilterFocus('jobTitle');
                }
              }}
              onFocus={() => {
                if (!isMobileDevice()) {
                  handleFilterFocus('jobTitle');
                }
              }}
              onBlur={() => { if (isMobileDevice()) handleFilterBlur(); }}
              disabled={loading}
            />
            <ChevronDown>▼</ChevronDown>
            
            <DesktopFilterOptions visible={!isMobileDevice() && activeFilter === 'jobTitle'}>
              {getFilteredOptions('jobTitle').length > 0 ? (
                getFilteredOptions('jobTitle').map((title) => (
                  <FilterOption key={title}>
                    <FilterCheckbox 
                      type="checkbox"
                      checked={jobTitleSearch === title}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setJobTitleSearch(title === jobTitleSearch ? '' : title);
                        setActiveFilter(null);
                      }}
                    />
                    {title}
                  </FilterOption>
                ))
              ) : (
                <NoResults>No matching job titles</NoResults>
              )}
            </DesktopFilterOptions>
          </FilterItem>
          
          {/* STATE FILTER */}
          <FilterItem ref={stateFilterRef}>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="16" height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
              <circle cx="12" cy="10" r="3"></circle>
            </svg>
            <FilterLabel></FilterLabel>
            <FilterInput
              type="text"
              placeholder={loading ? "Loading options..." : "Location"}
              value={stateSearch}
              onChange={(e) => {
                if (!isMobileDevice()) {
                  setStateSearch(e.target.value);
                }
              }}
              readOnly={isMobileDevice()}
              onClick={() => {
                if (isMobileDevice()) {
                  handleFilterFocus('state');
                }
              }}
              onFocus={() => {
                if (!isMobileDevice()) {
                  handleFilterFocus('state');
                }
              }}
              onBlur={() => { if (isMobileDevice()) handleFilterBlur(); }}
              disabled={loading}
            />
            <ChevronDown>▼</ChevronDown>
            
            <DesktopFilterOptions visible={!isMobileDevice() && activeFilter === 'state'}>
              {getFilteredOptions('state').length > 0 ? (
                getFilteredOptions('state').map((abbr) => (
                  <FilterOption key={abbr}>
                    <FilterCheckbox 
                      type="checkbox"
                      checked={stateSearch === abbr}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setStateSearch(abbr === stateSearch ? '' : abbr);
                        setActiveFilter(null);
                      }}
                    />
                    {getStateDisplayName(abbr)}
                  </FilterOption>
                ))
              ) : (
                <NoResults>No matching states</NoResults>
              )}
            </DesktopFilterOptions>
          </FilterItem>
          
          <SearchButton onClick={handleSearchWithCollapsedFilters} disabled={loading}>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="16" height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
            Search
          </SearchButton>
        </FilterBar>
        
        <Button to="/jobs" onClick={() => window.scrollTo(0, 0)}>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" height="16" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
          View All Open Positions
        </Button>
      </HeroContent>
    </HeroSection>
  );
};

export default EnhancedHeroSection;