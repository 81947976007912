import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import salesforceApi from '../services/salesforceApi';
import BackToHomeLink from '../components/BackToHomeLink';
import { formatSpecialtyJobTitle } from '../utils/formatting';


const PageContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
  
  .desktop-only-days {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const PageHeader = styled.div`
  margin-bottom: 2rem;
`;

const PageTitle = styled.h1`
  margin-bottom: 1rem;
  color: #2c3e50;
`;

const PageDescription = styled.p`
  color: #7f8c8d;
  font-size: 1.1rem;
`;

const PageLayout = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FilterSidebar = styled.div`
  flex: 0 0 280px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 1.5rem;
  position: sticky;
  top: 0;
  align-self: flex-start;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(52, 152, 219, 0.3);
    border-radius: 3px;
    transition: background 0.2s ease;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(52, 152, 219, 0.6);
  }

  @media (max-width: 768px) {
    position: static;
    width: 100%;
    max-height: ${({ isCollapsed }) => (isCollapsed ? '52px' : 'none')};
    overflow: ${({ isCollapsed }) => (isCollapsed ? 'hidden' : 'visible')};
    padding: ${({ isCollapsed }) => (isCollapsed ? '.75rem 1rem' : '1.5rem')};
    margin-bottom: ${({ isCollapsed }) => (isCollapsed ? '.5rem' : '1rem')};
    transition: all 0.3s ease;
  }
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ isCollapsed }) => (isCollapsed ? '0' : '1rem')};
`;

const FilterHeading = styled.h2`
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 1rem;
  color: #2c3e50;
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const ClearFiltersButton = styled.button`
  background: #28346c;
  border: none;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0.5rem 4rem;
  border-radius: 4px;
  transition: background-color 0.3s;
  transform: scale(1.1);
  
  &:hover {
    background-color: #1f2a50;
  }
`;

const FilterSection = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
  
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const FilterTitle = styled.h3`
  font-size: 1rem;
  color: #2c3e50;
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0.5rem 0;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 0.9rem;
  
  &:focus {
    outline: none;
    border-color: #28346c;
  }
  
  @media (max-width: 768px) {
    padding: 0.75rem;
    font-size: 1rem;
    cursor: pointer;
  }
`;

const FilterSelectButton = styled.div`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 0.9rem;
  background-color: white;
  color: #757575;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:focus {
    outline: none;
    border-color: #28346c;
  }
  
  @media (max-width: 768px) {
    padding: 0.75rem;
    font-size: 1rem;
  }
`;

const ChevronDown = styled.span`
  position: absolute;
  right: 0.75rem;
  top: 40%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 0.8rem;
  color: #666;
`;

const FilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  padding-right: 0.5rem;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  z-index: 10;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    position: fixed;
    width: 90%;
    max-width: 350px;
    max-height: 60vh;
    padding: 1.5rem;
    z-index: 1000;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  }
`;

const FilterOption = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #2c3e50;
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: #f5f5f5;
    color: #28346c;
  }
  
  @media (max-width: 768px) {
    padding: 12px 8px; /* Larger touch target for mobile */
    font-size: 1rem;
  }
`;

const FilterCheckbox = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
  
  @media (max-width: 768px) {
    transform: scale(1.2); /* Larger checkbox on mobile */
    margin-right: 0.75rem;
  }
`;

const FilterCount = styled.span`
  margin-left: 0.25rem;
  color: #7f8c8d;
  font-size: 0.8rem;
`;

const NoResultsMessage = styled.div`
  font-size: 0.85rem;
  color: #7f8c8d;
  font-style: italic;
  padding: 0.5rem;
  
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 1rem;
    text-align: center;
  }
`;

const Overlay = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  backdrop-filter: blur(2px);
`;

const CloseButton = styled.button`
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: none;
  border: none;
  color: #28346c;
  font-size: 1.5rem;
  cursor: pointer;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  
  &:hover {
    background-color: rgba(40, 52, 108, 0.1);
  }
  
  @media (max-width: 768px) {
    display: flex;
  }
`;

const ActiveFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const FilterTag = styled.div`
  background-color: #e1f0fa;
  color: #28346c;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
`;

const RemoveFilterButton = styled.button`
  background: none;
  border: none;
  color: #28346c;
  margin-left: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  
  &:hover {
    color: #e74c3c;
  }
`;

const JobsContainer = styled.div`
  flex: 1;
`;

const ResultsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 0.95rem;
  color: #7f8c8d;
`;

const SortDropdown = styled.select`
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  font-size: 0.9rem;
  color: #28346c;

  &:focus {
    outline: none;
    border-color: #28346c;
  }
`;

const JobsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
`;

const JobCard = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1.25rem;
  background-color: white;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 100%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    padding: 1rem;
    overflow-x: hidden;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const JobTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  color: #2c3e50;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 768px) {
    font-size: 1.3rem;
    margin-bottom: 0.25rem;
  }
`;

const JobId = styled.div`
  font-size: 0.9rem;
  color: #7f8c8d;
  text-align: right;
  
  @media (max-width: 768px) {
    display: none; /* Hide on mobile */
  }
`;

const JobMeta = styled.div`
  margin: 1rem 0;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  padding-bottom: 5px;
  
  &::-webkit-scrollbar {
    height: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
  }
  
  @media (max-width: 768px) {
    margin: 0.75rem 0;
  }
`;

const JobMetaItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 140px;
  margin-right: 16px;
`;

const JobMetaLabel = styled.span`
  font-size: 0.8rem;
  font-weight: 600;
  color: #7f8c8d;
  text-transform: uppercase;
  white-space: nowrap;
`;

const JobMetaValue = styled.span`
  font-size: 0.95rem;
  color: #28346c;
  white-space: nowrap;
`;

const JobLocation = styled.div`
  font-size: 0.9rem;
  color: #28346c;
  margin-bottom: 1rem;
  white-space: nowrap;
  
  @media (max-width: 768px) {
    margin-bottom: 0.75rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

// Custom Link component that scrolls to top on click
const ScrollToTopLink = ({ to, className, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Link to={to} className={className} onClick={handleClick} {...rest}>
      {children}
    </Link>
  );
};

export const ViewButton = styled(({ to, ...props }) => {
  const location = useLocation();
  const currentSearch = location.search;
  
  // Append the current search parameters to the job details URL
  // This will allow the back link to preserve filters
  const destinationPath = `${to}?from=${encodeURIComponent(currentSearch)}`;
  
  return <ScrollToTopLink to={destinationPath} {...props} />;
})`
  flex: 1;
  text-align: center;
  background-color: #28346c;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
    transition: all 0.6s ease;
  }
  
  &:hover {
    background-color: #1f2a50;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(40,52,108,0.25);
  }
  
  &:hover::before {
    left: 100%;
  }
  
  &:active {
    transform: translateY(-1px);
  }
`;

export const ApplyButton = styled(ScrollToTopLink)`
  flex: 1;
  text-align: center;
  background-color: #28346c;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
    transition: all 0.6s ease;
  }
  
  &:hover {
    background-color: #1f2a50;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(40,52,108,0.25);
  }
  
  &:hover::before {
    left: 100%;
  }
  
  &:active {
    transform: translateY(-1px);
  }
`;

const LoadingState = styled.div`
  text-align: center;
  padding: 3rem;
  font-size: 1.1rem;
  color: #7f8c8d;
`;

// Loading animation components
const LoadingDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`;

const FilterContent = styled.div`
  @media (max-width: 768px) {
    display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  }
`;


const ToggleButton = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: center;
    background: none;
    border: none;
    font-size: 0.85rem;
    font-weight: 600;
    color: #28346c;
    cursor: pointer;
    padding: 0;
  }
`;


const XIcon = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
`;

const ChevronDownIcon = styled.span`
  margin-left: 0.5rem;
  font-size: 0.75rem;
`;

const MobileJobMeta = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: #7f8c8d;
    margin-bottom: 0.75rem;
  }
`;

const MetaSeparator = styled.span`
  margin: 0 0.5rem;
  color: #d1d1d1;
`;

const LoadingDot = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: #28346c;
  border-radius: 50%;
  animation: bounce-pulse 1.4s infinite ease-in-out;
  animation-delay: ${props => props.delay || '0s'};

  @keyframes bounce-pulse {
    0%, 80%, 100% {
      transform: scale(0.6);
      opacity: 0.6;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const ErrorState = styled.div`
  text-align: center;
  padding: 2rem;
  color: #e74c3c;
  border: 1px solid #e74c3c;
  border-radius: 8px;
`;

const NoJobsMessage = styled.div`
  text-align: center;
  padding: 3rem;
  color: #7f8c8d;
  font-size: 1.1rem;
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
`;

const ResultsRangeInfo = styled.div`
  font-size: 0.9rem;
  color: #28346c;
  margin-bottom: 0.5rem;
`;

const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
  }
`;

const PageButton = styled.button`
  padding: 0.5rem 0.75rem;
  border: none;
  background-color: #f1f1f1;
  color: #28346c;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;

  &:hover {
    background-color: #e0e0e0;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const PageNumber = styled(PageButton)`
  ${({ active }) =>
    active &&
    `
    background-color: #28346c;
    color: white;
    font-weight: bold;
  `}
`;

const ResultsDropdown = styled.select`
  padding: 0.5rem;
  margin-left: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  font-size: 0.9rem;
  color: #28346c;

  &:focus {
    outline: none;
    border-color: #28346c;
  }
  
  @media (max-width: 768px) {
    display: block;
    margin: 1rem auto 0;
    width: 150px;
    text-align: center;
  }
`;

// State abbreviation to full state name mapping
const STATE_NAMES = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "FL": "Florida",
  "GA": "Georgia",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming",
  "DC": "District of Columbia",
  "PR": "Puerto Rico",
  "VI": "Virgin Islands",
  "GU": "Guam",
  "AS": "American Samoa",
  "MP": "Northern Mariana Islands",
};

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const JobListings = () => {

  useEffect(() => {
    document.title = "Healthcare Jobs | Explore Top Medical Career Opportunities with Capstone";
      
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Find the best healthcare jobs with Capstone. Explore exciting medical career opportunities, including nursing, therapy, and allied health positions nationwide.');
    }
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  
  const [filters, setFilters] = useState({
    jobTypes: [],
    jobTitles: [],
    states: [],
    specialties: [],
    shifts: [],
    certifications: []
  });
  
  const [filterOptions, setFilterOptions] = useState({
    jobTypes: [],
    jobTitles: [],
    states: [],
    specialties: [],
    shifts: [],
    certifications: []
  });
  
  const [searchTerms, setSearchTerms] = useState({
    jobTypes: '',
    jobTitles: '',
    states: '',
    specialties: '',
    shifts: '',
    certifications: ''
  });
  
  const [activeFilter, setActiveFilter] = useState(null);
  const [filterSidebarCollapsed, setFilterSidebarCollapsed] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  
  const [sortOption, setSortOption] = useState('');
  
  // Create refs at the top level of the component
  const jobTypesRef = useRef(null);
  const jobTitlesRef = useRef(null);
  const statesRef = useRef(null);
  const specialtiesRef = useRef(null);
  const shiftsRef = useRef(null);
  const certificationsRef = useRef(null);
  
  // Group refs in a memoized object
  const filterRefs = useMemo(() => ({
    jobTypes: jobTypesRef,
    jobTitles: jobTitlesRef,
    states: statesRef,
    specialties: specialtiesRef,
    shifts: shiftsRef,
    certifications: certificationsRef
  }), []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Add click outside handler for desktop
  useEffect(() => {
    // Only add the listener if we're on desktop and a dropdown is active
    if (!isMobile && activeFilter !== null) {
      const handleClickOutside = (event) => {
        // Check if the click was outside the active filter
        if (
          filterRefs[activeFilter] && 
          !filterRefs[activeFilter].current?.contains(event.target)
        ) {
          setActiveFilter(null);
        }
      };
      
      // Add the event listener
      document.addEventListener('mousedown', handleClickOutside);
      
      // Clean up
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [activeFilter, isMobile, filterRefs, setActiveFilter]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setLoading(true);
        setError('');
        console.log('Attempting to fetch jobs...');
        const jobsData = await salesforceApi.getJobs();
        
        const queryParams = new URLSearchParams(window.location.search);
        const shouldCollapseFilters = queryParams.get('collapsed') === 'true';
        
        if (shouldCollapseFilters) {
          setFilterSidebarCollapsed(true);
        }
        
        if (!jobsData || jobsData.length === 0) {
          console.log('No jobs returned from API');
          setJobs([]);
        } else {
          console.log(`Fetched ${jobsData.length} jobs successfully`);
          setJobs(jobsData);
          extractFilterOptions(jobsData);
        }
      } catch (err) {
        console.error('Error in JobListings component:', err);
        setError(`Failed to load job listings: ${err.message}. Please check your network connection and try again. If the problem persists, contact support.`);
        setJobs([]);
      } finally {
        setLoading(false);
      }
    };
  
    fetchJobs();
  }, []);

  const extractFilterOptions = (jobsData) => {
    if (jobsData.length > 0) {
      console.log('First job data:', jobsData[0]);
      console.log('All fields in first job:', Object.keys(jobsData[0]));
    }
    const options = {
      jobTypes: new Set(),
      jobTitles: new Set(),
      states: new Set(),
      specialties: new Set(),
      shifts: new Set(),
      certifications: new Set()
    };
    
    jobsData.forEach(job => {
      if (job.vms_order_type__c) {
        options.jobTypes.add(job.vms_order_type__c);
      } else if (job.Order_Type) {
        options.jobTypes.add(job.Order_Type);
      }
      
      if (job.JobTitlen) {
        options.jobTitles.add(job.JobTitlen);
      } else if (job.VMS_Job_Title__c) {
        options.jobTitles.add(job.VMS_Job_Title__c);
      } else if (job.AVTRRT__Job_Title__c) {
        options.jobTitles.add(job.AVTRRT__Job_Title__c);
      } else if (job.Job_Title__c) {
        options.jobTitles.add(job.Job_Title__c);
      } else {
        const orderType = job.vms_order_type__c || job.Order_Type || '';
        const specialty = job.Specialityn || job.Specialty || '';
        const title = job.JobTitlen || '';
        if (orderType || specialty || title) {
          const compositeTitle = `${orderType} ${specialty} ${title}`.trim();
          if (compositeTitle) {
            options.jobTitles.add(compositeTitle);
          }
        }
      }
      
      if (job.State) {
        options.states.add(job.State);
      }
      
      if (job.Specialityn) {
        options.specialties.add(job.Specialityn);
      } else if (job.Specialty) {
        options.specialties.add(job.Specialty);
      }
      
      if (job.Shiftn) {
        options.shifts.add(job.Shiftn);
      } else if (job.JR_Shift) {
        options.shifts.add(job.JR_Shift);
      }
    });
    
    setFilterOptions({
      jobTypes: Array.from(options.jobTypes).sort(),
      jobTitles: Array.from(options.jobTitles).sort(),
      states: Array.from(options.states).sort(),
      specialties: Array.from(options.specialties).sort(),
      shifts: Array.from(options.shifts).sort(),
      certifications: []
    });
  };

  useEffect(() => {
    const titleParam = searchParams.get('title');
if (titleParam && filterOptions.jobTitles.length > 0) {
  const titleList = titleParam.split(',');
  const validTitles = [];
  
  titleList.forEach(title => {
    const titleLower = title.toLowerCase();
    const exact = filterOptions.jobTitles.filter(
      t => t.toLowerCase() === titleLower
    );
    if (exact.length > 0) {
      validTitles.push(...exact);
    } else {
      const partial = filterOptions.jobTitles.filter(
        t => t.toLowerCase().includes(titleLower)
      );
      if (partial.length > 0) {
        validTitles.push(...partial);
      }
    }
  });

  if (validTitles.length > 0) {
    const uniqueValid = [...new Set(validTitles)];
    setFilters(prev => ({
      ...prev,
      jobTitles: uniqueValid
    }));
  }
}
    
    const stateParam = searchParams.get('state');
    if (stateParam && filterOptions.states.length > 0) {
      const matchingStates = filterOptions.states.filter(state => 
        state.toLowerCase() === stateParam.toLowerCase()
      );
      
      if (matchingStates.length > 0) {
        setFilters(prev => ({
          ...prev,
          states: matchingStates
        }));
      }
    }

    const specialtyParam = searchParams.get('specialty');
    if (specialtyParam && filterOptions.specialties.length > 0) {
      const specialtyList = specialtyParam.split(',');
      const validSpecialties = [];

      specialtyList.forEach(spec => {
        const specLower = spec.toLowerCase();
        const exact = filterOptions.specialties.filter(
          s => s.toLowerCase() === specLower
        );
        if (exact.length > 0) {
          validSpecialties.push(...exact);
        } else {
          const partial = filterOptions.specialties.filter(
            s => s.toLowerCase().includes(specLower)
          );
          if (partial.length > 0) {
            validSpecialties.push(...partial);
          }
        }
      });

      if (validSpecialties.length > 0) {
        const uniqueValid = [...new Set(validSpecialties)];
        setFilters(prev => ({
          ...prev,
          specialties: uniqueValid
        }));
      }
    }

    const sortParam = searchParams.get('sort');
    if (sortParam === 'recent') {
      setSortOption('recent');
    } else if (sortParam === 'highestPay') {
      setSortOption('highestPay');
    }
  }, [searchParams, filterOptions]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, sortOption]);

  const toggleFilterSidebar = () => {
    setFilterSidebarCollapsed(prev => !prev);
  };
  
  const handleFilterChange = (category, value) => {
    setFilters(prev => {
      const updatedFilters = { ...prev };
      if (updatedFilters[category].includes(value)) {
        updatedFilters[category] = updatedFilters[category].filter(item => item !== value);
      } else {
        updatedFilters[category] = [...updatedFilters[category], value];
      }
      
      // Update URL with filters
      updateUrlWithFilters(updatedFilters);
      
      return updatedFilters;
    });
  };
  
  // Function to update URL search parameters with current filters
  const updateUrlWithFilters = (currentFilters) => {
    const params = new URLSearchParams();
    
    if (currentFilters.jobTitles.length > 0) {
      params.set('title', currentFilters.jobTitles.join(','));
    }
    
    if (currentFilters.states.length > 0) {
      params.set('state', currentFilters.states.join(','));
    }
    
    if (currentFilters.specialties.length > 0) {
      params.set('specialty', currentFilters.specialties.join(','));
    }
    
    if (sortOption) {
      params.set('sort', sortOption);
    }
    
    if (currentFilters.jobTypes.length > 0) {
      params.set('jobType', currentFilters.jobTypes.join(','));
    }
    
    if (currentFilters.shifts.length > 0) {
      params.set('shift', currentFilters.shifts.join(','));
    }
    
    setSearchParams(params);
  };
  
  const handleSearchChange = (category, value) => {
    setSearchTerms(prev => ({
      ...prev,
      [category]: value
    }));
  };
  
  const [activeFilterPosition, setActiveFilterPosition] = useState({ top: 0, left: 0 });

  const handleFilterClick = (category, event) => {
    setActiveFilter(category);
    
    if (window.innerWidth <= 768) {
      const rect = event.currentTarget.getBoundingClientRect();
      const position = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX
      };
      
      setActiveFilterPosition(position);
      setOverlayVisible(true);
    }
  };
  
  const handleSearchFocus = (category) => {
    setActiveFilter(category);
    if (window.innerWidth <= 768) {
      setOverlayVisible(true);
    }
  };
  
  // Only run the blur logic on mobile
  const handleSearchBlur = () => {
    if (!isMobile) return;
    setTimeout(() => {
      setActiveFilter(null);
      setOverlayVisible(false);
    }, 150);
  };
  
  const handleCloseFilter = () => {
    setActiveFilter(null);
    setOverlayVisible(false);
  };
  
  const getFilteredOptions = (category) => {
    const filteredOptions = filterOptions[category].filter(option => 
      option.toLowerCase().includes(searchTerms[category].toLowerCase())
    );
    
    // For states category, sort alphabetically only
    if (category === 'states') {
      return filteredOptions.sort((a, b) => a.localeCompare(b));
    }
    
    // For all other categories, maintain the original sorting logic (by count first)
    return filteredOptions.sort((a, b) => {
      const countA = countJobsForFilter(category, a);
      const countB = countJobsForFilter(category, b);
      
      if (countA !== countB) {
        return countB - countA;
      }
      
      return a.localeCompare(b);
    });
  };
  
  const clearAllFilters = () => {
    setFilters({
      jobTypes: [],
      jobTitles: [],
      states: [],
      specialties: [],
      shifts: [],
      certifications: []
    });
    
    setSearchTerms({
      jobTypes: '',
      jobTitles: '',
      states: '',
      specialties: '',
      shifts: '',
      certifications: ''
    });
    
    setSortOption('');
    
    // Clear URL parameters
    setSearchParams(new URLSearchParams());
  };
  
  const removeFilter = (category, value) => {
    setFilters(prev => {
      const updatedFilters = {
        ...prev,
        [category]: prev[category].filter(item => item !== value)
      };
      
      // Update URL with updated filters
      updateUrlWithFilters(updatedFilters);
      
      return updatedFilters;
    });
  };
  
  const countJobsForFilter = (category, value) => {
    return jobs.filter(job => {
      switch(category) {
        case 'jobTypes':
          return (job.vms_order_type__c === value || job.Order_Type === value);
        case 'jobTitles':
          if (job.JobTitlen === value) return true;
          if (job.VMS_Job_Title__c === value) return true;
          if (job.AVTRRT__Job_Title__c === value) return true;
          if (job.Job_Title__c === value) return true;
          const orderType = job.vms_order_type__c || job.Order_Type || '';
          const specialty = job.Specialityn || job.Specialty || '';
          const title = job.JobTitlen || '';
          const compositeTitle = `${orderType} ${specialty} ${title}`.trim();
          return compositeTitle === value;
        case 'states':
          return job.State === value;
        case 'specialties':
          return (job.Specialityn === value || job.Specialty === value);
        case 'shifts':
          return (job.Shiftn === value || job.JR_Shift === value);
        default:
          return false;
      }
    }).length;
  };
  
  const hasActiveFilters = Object.entries(filters)
    .some(([_, values]) => values.length > 0);

  const getCategoryDisplayName = (category) => {
    switch(category) {
      case 'jobTypes': return 'Job Type';
      case 'jobTitles': return 'Job Title';
      case 'states': return 'State';
      case 'specialties': return 'Specialty';
      case 'shifts': return 'Shift';
      case 'certifications': return 'Certification';
      default: return category;
    }
  };

  const getStateDisplayValue = (stateAbbr) => {
    return STATE_NAMES[stateAbbr] || stateAbbr;
  };

  const formatDaysOpenText = (daysOpen) => {
    if (daysOpen === undefined || daysOpen === null) return '';
    
    const days = parseInt(daysOpen, 10);
    if (isNaN(days)) return '';
    
    if (days === 0) return 'Today';
    return days === 1 ? '1 day ago' : `${days} days ago`;
  };
  
  const getHighestPayValue = (payRangeStr) => {
    if (!payRangeStr) return 0;
    
    const matches = payRangeStr.match(/\$(\d+)/g);
    if (!matches || matches.length === 0) return 0;
    
    return Math.max(...matches.map(match => parseInt(match.replace('$', ''), 10)));
  };
  
  const handleSortChange = (e) => {
    const newSortOption = e.target.value;
    setSortOption(newSortOption);
    
    // Update search params with the new sort option
    const params = new URLSearchParams(searchParams);
    if (newSortOption) {
      params.set('sort', newSortOption);
    } else {
      params.delete('sort');
    }
    setSearchParams(params);
  };

  const getPlaceholderText = (category) => {
    const selectedCount = filters[category].length;
    
    if (selectedCount === 0) {
      switch(category) {
        case 'jobTypes': return 'Select job types...';
        case 'jobTitles': return 'Select job titles...';
        case 'states': return 'Select states...';
        case 'specialties': return 'Select specialties...';
        case 'shifts': return 'Select shifts...';
        case 'certifications': return 'Select certifications...';
        default: return 'Select options...';
      }
    } else {
      return `${selectedCount} selected`;
    }
  };

  const filteredJobs = jobs.filter(job => {
    if (filters.jobTypes.length > 0) {
      const jobType = job.vms_order_type__c || job.Order_Type;
      if (!jobType || !filters.jobTypes.includes(jobType)) {
        return false;
      }
    }
    
    if (filters.jobTitles.length > 0) {
      let jobTitleMatch = false;
      
      if (job.JobTitlen && filters.jobTitles.includes(job.JobTitlen)) {
        jobTitleMatch = true;
      } else if (job.VMS_Job_Title__c && filters.jobTitles.includes(job.VMS_Job_Title__c)) {
        jobTitleMatch = true;
      } else if (job.AVTRRT__Job_Title__c && filters.jobTitles.includes(job.AVTRRT__Job_Title__c)) {
        jobTitleMatch = true;
      } else if (job.Job_Title__c && filters.jobTitles.includes(job.Job_Title__c)) {
        jobTitleMatch = true;
      } else {
        const orderType = job.vms_order_type__c || job.Order_Type || '';
        const specialty = job.Specialityn || job.Specialty || '';
        const title = job.JobTitlen || '';
        const compositeTitle = `${orderType} ${specialty} ${title}`.trim();
        
        if (filters.jobTitles.includes(compositeTitle)) {
          jobTitleMatch = true;
        }
      }
      
      if (!jobTitleMatch) {
        return false;
      }
    }
    
    if (filters.states.length > 0) {
      if (!job.State || !filters.states.includes(job.State)) {
        return false;
      }
    }
    
    if (filters.specialties.length > 0) {
      if (job.Specialityn && filters.specialties.includes(job.Specialityn)) {
      } else if (job.Specialty && filters.specialties.includes(job.Specialty)) {
      } else {
        return false;
      }
    }
    
    if (filters.shifts.length > 0) {
      if (job.Shiftn && filters.shifts.includes(job.Shiftn)) {
      } else if (job.JR_Shift && filters.shifts.includes(job.JR_Shift)) {
      } else {
        return false;
      }
    }
    
    return true;
  });
  
  const sortedJobs = [...filteredJobs].sort((a, b) => {
    if (sortOption === 'recent') {
      const daysOpenA = parseInt(a.Days_Open__c, 10) || 0;
      const daysOpenB = parseInt(b.Days_Open__c, 10) || 0;
      return daysOpenA - daysOpenB;
    } else if (sortOption === 'highestPay') {
      const payRangeA = a.CalculatedPayRange || a.payRange || '';
      const payRangeB = b.CalculatedPayRange || b.payRange || '';
      const highestPayA = getHighestPayValue(payRangeA);
      const highestPayB = getHighestPayValue(payRangeB);
      return highestPayB - highestPayA;
    }
    return 0;
  });

  const totalJobs = sortedJobs.length;
  const totalPages = Math.ceil(totalJobs / resultsPerPage) || 1;
  const startIndex = (currentPage - 1) * resultsPerPage;
  const currentJobs = sortedJobs.slice(startIndex, startIndex + resultsPerPage);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const getPageNumbers = () => {
    if (isMobile) {
      const pages = [];
      if (totalPages <= 3) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        if (currentPage <= 2) {
          pages.push(1, 2, 3, '...', totalPages);
        } else if (currentPage >= totalPages - 1) {
          pages.push(1, '...', totalPages - 2, totalPages - 1, totalPages);
        } else {
          pages.push(1, '...', currentPage, '...', totalPages);
        }
      }
      return pages;
    } else {
      const pages = [];
      if (totalPages <= 7) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        if (currentPage <= 4) {
          pages.push(1, 2, 3, 4, 5, '...', totalPages);
        } else if (currentPage >= totalPages - 3) {
          pages.push(1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
        } else {
          pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
        }
      }
      return pages;
    }
  };

  const handlePageChange = (page) => {
    if (page !== '...' && page !== currentPage) {
      setCurrentPage(page);
      scrollToTop();
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
      scrollToTop();
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
      scrollToTop();
    }
  };

  const handleResultsPerPageChange = (e) => {
    setResultsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
    scrollToTop();
  };

  const renderFilterInput = (category) => {
    if (isMobile) {
      return (
        <FilterSelectButton
          onClick={(e) => handleFilterClick(category, e)}
        >
          {getPlaceholderText(category)}
          <span>▼</span>
        </FilterSelectButton>
      );
    } else {
      return (
        <div style={{ position: 'relative' }}>
          <SearchInput 
            type="text"
            placeholder={getPlaceholderText(category)}
            value={searchTerms[category]}
            onChange={(e) => handleSearchChange(category, e.target.value)}
            onFocus={() => handleSearchFocus(category)}
            onBlur={() => { if (isMobile) handleSearchBlur(); }}
          />
          <ChevronDown>▼</ChevronDown>
        </div>
      );
    }
  };

  return (
    <div>
      <VisuallyHidden>Explore Healthcare Job Opportunities – Find Your Next Career with Capstone</VisuallyHidden>
    <div>
      <Overlay visible={overlayVisible} onClick={handleCloseFilter} />
      <PageContainer>
        <BackToHomeLink />
        <PageHeader>
          <PageTitle style={{ color: "#28346c" }}>Available Positions</PageTitle>
          <PageDescription>
            Explore our current job openings and find your next opportunity.
          </PageDescription>
        </PageHeader>

        {loading ? (
          <LoadingState>
            <LoadingDots>
              <LoadingDot delay="0s" />
              <LoadingDot delay="0.2s" />
              <LoadingDot delay="0.4s" />
            </LoadingDots>
          </LoadingState>
        ) : error ? (
          <ErrorState>{error}</ErrorState>
        ) : jobs.length === 0 ? (
          <NoJobsMessage>No open positions available at this time. Please check back later.</NoJobsMessage>
        ) : (
          <PageLayout>
            <FilterSidebar isCollapsed={filterSidebarCollapsed}>
              <FilterHeader isCollapsed={filterSidebarCollapsed}>
                <FilterHeading>
                  Refine Your Search
                </FilterHeading>
                <ToggleButton onClick={toggleFilterSidebar}>
                  {filterSidebarCollapsed ? (
                    <>
                      Expand
                      <ChevronDownIcon>▼</ChevronDownIcon>
                    </>
                  ) : (
                    <>
                      Collapse
                      <XIcon>×</XIcon>
                    </>
                  )}
                </ToggleButton>
              </FilterHeader>
              
              <FilterContent isCollapsed={filterSidebarCollapsed}>
                {filterOptions.jobTitles.length > 0 && (
                  <FilterSection ref={filterRefs.jobTitles}>
                    <FilterTitle>Job Title</FilterTitle>
                    {renderFilterInput('jobTitles')}
                    {activeFilter === 'jobTitles' && (
                      <FilterOptions style={{
                        top: isMobile ? `${Math.min(Math.max(activeFilterPosition.top - 200, 20), window.innerHeight - 400)}px` : 'auto',
                        left: isMobile ? `${Math.max(window.innerWidth * 0.05, 20)}px` : 'auto'
                      }}>
                        <CloseButton onClick={handleCloseFilter}>×</CloseButton>
                        {getFilteredOptions('jobTitles').length > 0 ? (
                          getFilteredOptions('jobTitles').map((jobTitle) => (
                            <FilterOption key={`jobTitle-${jobTitle}`}>
                              <FilterCheckbox 
                                type="checkbox"
                                checked={filters.jobTitles.includes(jobTitle)}
                                onChange={() => handleFilterChange('jobTitles', jobTitle)}
                              />
                              {jobTitle}
                              <FilterCount>({countJobsForFilter('jobTitles', jobTitle)})</FilterCount>
                            </FilterOption>
                          ))
                        ) : (
                          <NoResultsMessage>No job titles match your search</NoResultsMessage>
                        )}
                      </FilterOptions>
                    )}
                  </FilterSection>
                )}
                
                {filterOptions.specialties.length > 0 && (
                  <FilterSection ref={filterRefs.specialties}>
                    <FilterTitle>Speciality</FilterTitle>
                    {renderFilterInput('specialties')}
                    {activeFilter === 'specialties' && (
                      <FilterOptions style={{
                        top: isMobile ? `${Math.min(Math.max(activeFilterPosition.top - 200, 20), window.innerHeight - 400)}px` : 'auto',
                        left: isMobile ? `${Math.max(window.innerWidth * 0.05, 20)}px` : 'auto'
                      }}>
                        <CloseButton onClick={handleCloseFilter}>×</CloseButton>
                        {getFilteredOptions('specialties').length > 0 ? (
                          getFilteredOptions('specialties').map((specialty) => (
                            <FilterOption key={`specialty-${specialty}`}>
                              <FilterCheckbox 
                                type="checkbox"
                                checked={filters.specialties.includes(specialty)}
                                onChange={() => handleFilterChange('specialties', specialty)}
                              />
                              {specialty}
                              <FilterCount>({countJobsForFilter('specialties', specialty)})</FilterCount>
                            </FilterOption>
                          ))
                        ) : (
                          <NoResultsMessage>No specialties match your search</NoResultsMessage>
                        )}
                      </FilterOptions>
                    )}
                  </FilterSection>
                )}
                
                {filterOptions.states.length > 0 && (
                  <FilterSection ref={filterRefs.states}>
                    <FilterTitle>State</FilterTitle>
                    {renderFilterInput('states')}
                    {activeFilter === 'states' && (
                      <FilterOptions style={{
                        top: isMobile ? `${Math.min(Math.max(activeFilterPosition.top - 200, 20), window.innerHeight - 400)}px` : 'auto',
                        left: isMobile ? `${Math.max(window.innerWidth * 0.05, 20)}px` : 'auto'
                      }}>
                        <CloseButton onClick={handleCloseFilter}>×</CloseButton>
                        {getFilteredOptions('states').length > 0 ? (
                          getFilteredOptions('states').map((state) => (
                            <FilterOption key={`state-${state}`}>
                              <FilterCheckbox 
                                type="checkbox"
                                checked={filters.states.includes(state)}
                                onChange={() => handleFilterChange('states', state)}
                              />
                              {getStateDisplayValue(state)}
                              <FilterCount>({countJobsForFilter('states', state)})</FilterCount>
                            </FilterOption>
                          ))
                        ) : (
                          <NoResultsMessage>No states match your search</NoResultsMessage>
                        )}
                      </FilterOptions>
                    )}
                  </FilterSection>
                )}
                
                {filterOptions.shifts.length > 0 && (
                  <FilterSection ref={filterRefs.shifts}>
                    <FilterTitle>Shift</FilterTitle>
                    {renderFilterInput('shifts')}
                    {activeFilter === 'shifts' && (
                      <FilterOptions style={{
                        top: isMobile ? `${Math.min(Math.max(activeFilterPosition.top - 200, 20), window.innerHeight - 400)}px` : 'auto',
                        left: isMobile ? `${Math.max(window.innerWidth * 0.05, 20)}px` : 'auto'
                      }}>
                        <CloseButton onClick={handleCloseFilter}>×</CloseButton>
                        {getFilteredOptions('shifts').length > 0 ? (
                          getFilteredOptions('shifts').map((shift) => (
                            <FilterOption key={`shift-${shift}`}>
                              <FilterCheckbox 
                                type="checkbox"
                                checked={filters.shifts.includes(shift)}
                                onChange={() => handleFilterChange('shifts', shift)}
                              />
                              {shift}
                              <FilterCount>({countJobsForFilter('shifts', shift)})</FilterCount>
                            </FilterOption>
                          ))
                        ) : (
                          <NoResultsMessage>No shifts match your search</NoResultsMessage>
                        )}
                      </FilterOptions>
                    )}
                  </FilterSection>
                )}

                {hasActiveFilters && (
                  <div style={{ marginTop: '2rem', textAlign: 'center' }}>
                    <ClearFiltersButton onClick={clearAllFilters}>
                      Clear all filters
                    </ClearFiltersButton>
                  </div>
                )}
              </FilterContent>
            </FilterSidebar>
            
            <JobsContainer>
              {hasActiveFilters && (
                <ActiveFilters>
                  {Object.entries(filters)
                    .map(([category, values]) => 
                      values.map(value => (
                        <FilterTag key={`tag-${category}-${value}`}>
                          {getCategoryDisplayName(category)}: {category === 'states' ? getStateDisplayValue(value) : value}
                          <RemoveFilterButton onClick={() => removeFilter(category, value)}>
                            ×
                          </RemoveFilterButton>
                        </FilterTag>
                      ))
                    )}
                </ActiveFilters>
              )}
              
              <ResultsInfo>
                <div>
                  {totalJobs}
                  {totalJobs === 1000 ? '+' : ''} {totalJobs === 1 ? 'position' : 'positions'} found
                </div>
                <SortDropdown value={sortOption} onChange={handleSortChange}>
                  <option value="">Sort By</option>
                  <option value="recent">Recent</option>
                  <option value="highestPay">Highest Pay</option>
                </SortDropdown>
              </ResultsInfo>
              
              {totalJobs === 0 ? (
                <NoJobsMessage>
                  No jobs match your selected filters. Try adjusting your criteria.
                </NoJobsMessage>
              ) : (
                <>
                  <JobsList>
                    {currentJobs.map((job) => (
                      <JobCard key={job.Record_Id}>
                        <TitleContainer>
                          <JobTitle>
                            {job.Specialityn && job.JobTitlen
                              ? formatSpecialtyJobTitle(job.Specialityn, job.JobTitlen).trim() 
                              : job.JobTitlen || job.Specialityn || 'Job Title Not Available'}
                          </JobTitle>
                          <JobId>Job ID: {job.Client_Job_ID || job.JobNum || 'N/A'}</JobId>
                        </TitleContainer>
                        
                        <MobileJobMeta>
                          {formatDaysOpenText(job.Days_Open__c) && (
                            <>
                              <span>{formatDaysOpenText(job.Days_Open__c)}</span>
                              <MetaSeparator>|</MetaSeparator>
                            </>
                          )}
                          <span>Job ID: {job.Client_Job_ID || job.JobNum || 'N/A'}</span>
                        </MobileJobMeta>
                        
                        {job.city && job.State && (
                          <JobLocation>
                            {job.city}, {getStateDisplayValue(job.State)}
                          </JobLocation>
                        )}
                        
                        <JobMeta>
                          {(job.Shiftn || job.JR_Shift) && (
                            <JobMetaItem>
                              <JobMetaLabel>Shift</JobMetaLabel>
                              <JobMetaValue>{job.Shiftn || job.JR_Shift}</JobMetaValue>
                            </JobMetaItem>
                          )}
                          
                          {(job.CalculatedPayRange || job.payRange) && (
                            <JobMetaItem>
                              <JobMetaLabel>Weekly Pay Range</JobMetaLabel>
                              <JobMetaValue>
                                {(job.CalculatedPayRange || job.payRange).replace(/ weekly$/, '')}
                              </JobMetaValue>
                            </JobMetaItem>
                          )}
                          
                          {job.Days_Open__c !== undefined && job.Days_Open__c !== null && (
                            <div
                              style={{
                                marginLeft: 'auto',
                                alignSelf: 'center',
                                textAlign: 'right',
                                color: '#7f8c8d',
                                fontSize: '0.9rem'
                              }}
                              className="desktop-only-days"
                            >
                              {formatDaysOpenText(job.Days_Open__c)}
                            </div>
                          )}
                        </JobMeta>
                        
                        <ButtonContainer>
                          <ViewButton to={`/jobs/${job.Record_Id}`}>View Details</ViewButton>
                          <ApplyButton to={`/apply/${job.Record_Id}`}>Apply Now</ApplyButton>
                        </ButtonContainer>
                      </JobCard>
                    ))}
                  </JobsList>
                  
                  <PaginationContainer>
                    <ResultsRangeInfo>
                      {totalJobs > 0 &&
                        `Showing ${startIndex + 1}-${Math.min(startIndex + resultsPerPage, totalJobs)} of ${totalJobs} positions`
                      }
                    </ResultsRangeInfo>
                    <PaginationControls>
                      <PageButton onClick={handlePrevious} disabled={currentPage === 1}>
                        Previous
                      </PageButton>
                      {getPageNumbers().map((page, index) => 
                        page === '...' ? (
                          <PageButton key={`ellipsis-${index}`} disabled>
                            …
                          </PageButton>
                        ) : (
                          <PageNumber
                            key={page}
                            active={page === currentPage}
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </PageNumber>
                        )
                      )}
                      <PageButton onClick={handleNext} disabled={currentPage === totalPages}>
                        Next
                      </PageButton>
                      <ResultsDropdown value={resultsPerPage} onChange={handleResultsPerPageChange}>
                        <option style={{ color: '#28346c' }} value={10}>10 per page</option>
                        <option style={{ color: '#28346c' }} value={25}>25 per page</option>
                        <option style={{ color: '#28346c' }} value={100}>100 per page</option>
                      </ResultsDropdown>
                    </PaginationControls>
                  </PaginationContainer>
                </>
              )}
            </JobsContainer>
          </PageLayout>
        )}
      </PageContainer>
      </div>
    </div>
  );
};

export default JobListings;