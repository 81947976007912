// src/pages/Nursing.jsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackToHomeLink from '../components/BackToHomeLink';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  background-color: #28346c;
  color: white;
  padding: 4rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: center;
`;

const HeroTitle = styled.h1`
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-size: 2.5rem;
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.9;
  color: white;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const CTASection = styled.div`
  background-color: #f8f9fa;
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CTATitle = styled.h2`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.8rem;
`;

const CTADescription = styled.p`
  color: #54698d;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #28346c;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1e2756;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const StatsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
`;

const StatCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StatNumber = styled.h3`
  font-size: 2.5rem;
  color: #28346c;
  margin-bottom: 0.5rem;
`;

const StatText = styled.p`
  color: #54698d;
  font-size: 1rem;
`;

const ContentSection = styled.div`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  color: #28346c;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  text-align: center;
`;

const JobsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
`;

const JobCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const JobTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const JobDescription = styled.p`
  color: #54698d;
  line-height: 1.6;
`;

const ContactBanner = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContactText = styled.h3`
  color: #28346c;
  margin: 0;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const Nursing = () => {

  useEffect(() => {
    document.title = "Explore Nursing Jobs | Travel & Agency Nursing Careers – Capstone Health";
      
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Find top nursing jobs, including travel and agency nursing positions, with Capstone. Advance your career with rewarding opportunities in healthcare staffing.');
    }
  }, []);

  return (
    <div>
      <VisuallyHidden>Explore Exciting Nursing Opportunities with Capstone</VisuallyHidden>
    <div>
      <PageContainer>
      <BackToHomeLink />
        <HeroSection>
          <HeroTitle>Capstone Health Nursing Staffing Services</HeroTitle>
          <HeroSubtitle>
            Discover the pinnacle of nursing staffing with Capstone Health. Our expansive healthcare staffing services encompass a diverse range of specialized roles, from Medical Surgical Nurses (Med Surg) to Intensive Care Nurses (ICU Nurse), Neonatal Intensive Care Nurses (NICU Nurse), Telemetry Nurses, Psychiatric Nurses, Emergency Room Nurses (ER Nurse), Labor & Delivery Nurses (L&D Nurse), Operating Room Nurses (OR Nurse), Surgical Technologists (Surgical Tech), and Sterile Processing Techs (SPT).
          </HeroSubtitle>
        </HeroSection>

        <CTASection>
          <CTATitle>Seeking Nursing Opportunities?</CTATitle>
          <CTADescription>
            Explore a wide range of nursing positions nationwide with Capstone Health. Embark on your nursing career journey today.
          </CTADescription>
          <Button to="/contact">Contact Us</Button>
        </CTASection>

        <SectionTitle>Registered Nurses</SectionTitle>
        <StatsSection>
          <StatCard>
            <StatText>Most demanded Profession</StatText>
            <StatNumber>4th</StatNumber>
          </StatCard>
          <StatCard>
            <StatText>About a quarter of American Registered Nurse are 50 years old or older, so the next 15 years will see more RNs retiring</StatText>
          </StatCard>
          <StatCard>
            <StatText>Nearly 11 million more nurses are required to fulfil the already existing nurses shortage in the USA</StatText>
          </StatCard>
          <StatCard>
            <StatText>The demand for registered nurses would hit more than 3.6 million by the year 2030</StatText>
          </StatCard>
        </StatsSection>

        <ContentSection>
          <JobsGrid>
            <JobCard>
              <JobTitle>Medical Surgical Nurse (Med Surg)</JobTitle>
              <JobDescription>
                Experience the expertise of our highly-skilled Medical-Surgical Nurses. At Capstone Health, we connect these dedicated professionals with prestigious medical facilities, ensuring exceptional patient care during the postoperative period. With their broad medical knowledge and acute care skills, our Med Surg Nurses plays a crucial role in promoting recovery and delivering compassionate support to patients and their families.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Intensive Care Nurse (ICU Nurse)</JobTitle>
              <JobDescription>
                Our ICU Nurses are the backbone of critical care. At Capstone Health, we provide access to these specialized professionals who excel in delivering life-saving interventions and monitoring critically ill patients. Equipped with advanced skills and quick decision-making abilities, our ICU Nurses work tirelessly to provide unparalleled care and ensure the best possible outcomes for patients facing severe medical conditions.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Neonatal Intensive Care Nurse (NICU Nurse)</JobTitle>
              <JobDescription>
                Welcoming our youngest patients into the world, our NICU Nurses are compassionate experts in neonatal care. At Capstone Health, we match these skilled professionals with facilities that provide cutting-edge care for premature or critically ill newborns. Their specialized training and nurturing care create an environment where even the tiniest patients can thrive and grow.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Telemetry Nurse</JobTitle>
              <JobDescription>
                Monitor patient progress with confidence, courtesy of our exceptional Telemetry Nurses. At Capstone Health, we connect facilities with these vigilant professionals who specialize in continuous monitoring of patient's vital signs and telemetry data. With their acute assessment skills, Telemetry Nurses play a vital role in detecting changes in patient conditions, enabling timely interventions for improved outcomes.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Psychiatric Nurse</JobTitle>
              <JobDescription>
                Our empathetic and experienced Psychiatric Nurses bring compassion and expertise to mental health care. At Capstone Health, we facilitate the placement of these professionals in facilities where they make a profound difference in the lives of patients struggling with mental health challenges. With their therapeutic approaches and understanding, our Psychiatric Nurses provide comprehensive care and foster healing environments.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Emergency Room Nurse (ER Nurse)</JobTitle>
              <JobDescription>
                Our skilled Emergency Medicine Nurses (ER Nurses) are the frontline heroes in critical care. With rapid assessment and decisive action, they manage urgent situations, stabilize patients, and provide compassionate care. Their expertise in trauma, triage, and emergency procedures ensures timely and effective interventions. Count on our ER Nurses to deliver exceptional patient-centered care, ensuring optimal outcomes in high-pressure environments.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Labor & Delivery Nurse (L&D Nurse)</JobTitle>
              <JobDescription>
                Celebrate the miracle of life with our skilled Labor & Delivery Nurses. At Capstone Health, we match these compassionate professionals with birthing centres and maternity wards, ensuring mothers receive exceptional care throughout the labour process. With their expertise in supporting new mothers and safely delivering babies, our L&D Nurses create a memorable and joyous experience for families.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Operating Room Nurse (OR Nurse)</JobTitle>
              <JobDescription>
                Experience surgical excellence with our proficient OR Nurses. At Capstone Health, we connect these detail-oriented professionals with surgical teams, providing vital support throughout the perioperative process. With their surgical expertise and meticulous attention to detail, our OR Nurses contribute to successful surgeries and optimal patient outcomes.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Surgical Technologist</JobTitle>
              <JobDescription>
                Celebrate the miracle of life with our skilled Labor & Delivery Nurses. At Capstone Health, we match these compassionate professionals with birthing centers and maternity wards, ensuring mothers receive exceptional care throughout the labor process. With their expertise in supporting new mothers and safely delivering babies, our L&D Nurses create a memorable and joyous experience for families.
              </JobDescription>
            </JobCard>
          </JobsGrid>
        </ContentSection>

        <ContactBanner>
          <ContactText>DO YOU HAVE A QUESTION?</ContactText>
          <Button to="tel:+15154462266">Call (515) 446-2266</Button>
        </ContactBanner>
      </PageContainer>
      </div>
    </div>
  );
};

export default Nursing;