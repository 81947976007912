// src/pages/Therapy.jsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackToHomeLink from '../components/BackToHomeLink';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  background-color: #28346c;
  color: white;
  padding: 4rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: center;
`;

const HeroTitle = styled.h1`
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-size: 2.5rem;
`;

const HeroDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: white;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const CTASection = styled.div`
  background-color: #f8f9fa;
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CTATitle = styled.h2`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.8rem;
`;

const CTADescription = styled.p`
  color: #54698d;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #28346c;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1e2756;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ContentSection = styled.div`
  margin-bottom: 3rem;
`;

const InfoCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const InfoTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const InfoText = styled.p`
  color: #54698d;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const SectionTitle = styled.h2`
  color: #28346c;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  text-align: center;
`;

const BenefitsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
`;

const BenefitCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
`;

const BenefitText = styled.p`
  color: #54698d;
  line-height: 1.5;
  margin: 0;
`;

const JobsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
`;

const JobCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const JobTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const JobDescription = styled.p`
  color: #54698d;
  line-height: 1.6;
`;

const ContactBanner = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContactText = styled.h3`
  color: #28346c;
  margin: 0;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const Therapy = () => {

  useEffect(() => {
    document.title = "Occupational, Physical & Respiratory Therapist Jobs | Explore Therapy Careers with Capstone";
      
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Explore exciting Occupational Therapist, Physical Therapist, and Respiratory Therapist jobs with Capstone. Find rewarding therapy careers and advance your healthcare journey today.');
    }
  }, []);

  return (
    <div>
    <VisuallyHidden>Therapy Jobs – Occupational, Physical & Respiratory Therapist Careers</VisuallyHidden>
    
    <div>
      <PageContainer>
      <BackToHomeLink />
        <HeroSection>
          <HeroTitle>Therapy</HeroTitle>
          <HeroDescription>
            Capstone Health is your gateway to a world of therapy staffing opportunities, encompassing a diverse array of roles designed to make a profound impact. Whether you're a skilled Physical Therapist (PT), compassionate Physical Therapist Assistant (PTA), dedicated Occupational Therapist (OT), proactive Occupational Therapist Assistant (OTA/COTA), articulate Speech Language Pathologist (SLP), or detail-oriented Pharmacy Technician, Capstone Health is your partner in advancing your therapy career. Our extensive network of healthcare establishments ensures that your expertise aligns perfectly with the needs of patients, while our expert guidance simplifies your job search. Discover your path to success with Capstone Health today.
          </HeroDescription>
        </HeroSection>

        <CTASection>
          <CTATitle>Are you looking for opportunities in the field of therapy?</CTATitle>
          <CTADescription>
            Discover a wide range of therapy positions across the nation through Capstone Health.
            Embark on a fulfilling career journey in therapy with us.
          </CTADescription>
          <Button to="/contact">Contact Us</Button>
        </CTASection>

        <ContentSection>
          <InfoCard>
            <InfoTitle>Discover a realm of possibilities for therapy professionals.</InfoTitle>
            <InfoText>
              Are you ready to advance your therapy career and create a significant impact on patient well-being? If you're a dedicated healthcare expert seeking the ideal role within an organization that aligns with your aspirations, Capstone Health is your partner. With our profound industry insight, we seamlessly connect you with facilities that resonate with your goals. Capstone Health has a proven track record of matching skilled therapy professionals with top-tier healthcare institutions. Rely on our expertise for a smooth job search, ensuring a constructive journey toward your therapy ambitions.
            </InfoText>
          </InfoCard>
          
          <SectionTitle>Advantages for Capstone Health's therapy professionals</SectionTitle>
          <BenefitsSection>
            <BenefitCard>
              <BenefitText>Comprehensive healthcare plans</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Dental and vision insurance</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Life insurance coverage</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Access to mental health resources</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Financial coaching services</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Supplementary income replacement plans</BenefitText>
            </BenefitCard>
          </BenefitsSection>

          <JobsGrid>
            <JobCard>
              <JobTitle>Physical Therapist (PT)</JobTitle>
              <JobDescription>
                Capstone Health acknowledges the pivotal role of Physical Therapists (PTs) in nurturing robust health and vibrant lifestyles. As healthcare experts, PTs collaborate with diverse individuals of varying ages and conditions, encompassing injuries, illnesses, chronic ailments, and disabilities. Key functions of PTs encompass:

                Diagnosing Physical Impairments: Through observing posture, movement, balance, strength, and flexibility, PTs assess the root cause and extent of a patient's issue. Utilizing tests and imaging techniques, they identify sources of pain or dysfunction.
                Creating Tailored Treatment Plans: PTs design personalized care plans aligned with patients' needs, goals, and preferences. They educate patients about expected outcomes, potential risks, and benefits.
                Providing Multifaceted Treatment: Using methods like exercises, manual therapy, modalities, assistive devices, and orthotics, PTs enhance patient mobility and function. They closely monitor progress and adjust treatment as needed.
                Preventing Recurrence: PTs guide patients in preventing or minimizing problem recurrence by imparting knowledge on health enhancement, injury prevention, ergonomics, fitness, and nutrition.
                Elevate your career as a PT with Capstone Health and contribute to fostering healthier lives.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Physical Therapist Assistant (PTA)</JobTitle>
              <JobDescription>
                Capstone Health recognizes the pivotal role that Physical Therapist Assistants (PTAs) play in healthcare. Operating under the guidance of Physical Therapists (PTs), PTAs enhance patient mobility and function across diverse settings like hospitals, clinics, schools, and home health care.

                Key Roles:

                Treatment Implementation: Collaborate with PTs to execute personalized treatment plans, employing exercises, manual therapy, modalities, and assistive tools for goal attainment and discomfort relief.
                Progress Tracking: Monitor and document patient progress and outcomes, promptly notifying PTs of any changes.
                Education and Support: Educate patients and families on exercises, equipment usage, injury prevention, and treatment adherence.
                Safety and Excellence: Uphold safety, hygiene, professionalism, and patient rights.
                Elevate your PTA career with Capstone Health and contribute to enhancing patient well-being.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Occupational Therapist (OT)</JobTitle>
              <JobDescription>
                At Capstone Health, we value the crucial role of Occupational Therapists (OT) in empowering individuals of all abilities to achieve daily activities and routines with confidence. OTs adopt a holistic approach, addressing physical, mental, emotional, and social needs while modifying environments for optimal functionality.

                Key Responsibilities:

                Evaluate patient conditions, performance, and history.
                Design tailored treatment plans to enhance mobility and independence.
                Deliver diverse treatments, including exercises and modalities.
                Educate patients and families on recovery techniques.
                Monitor progress and adjust plans as needed.
                Capstone Health invites OTs to join our network and shape lives through comprehensive care.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Occupational Therapist Assistant (OTA/COTA)</JobTitle>
              <JobDescription>
                Capstone Health acknowledges the significant role of Occupational Therapist Assistants (OTA/COTAs) in the realm of healthcare. Operating under the guidance of Occupational Therapists (OTs), OTA/COTAs aid patients in enhancing mobility and functionality. As Certified Occupational Therapy Assistants, they play pivotal roles:

                Delivering Care: OTA/COTAs actively aid OTs in executing patient-specific treatment plans. Their approaches encompass diverse techniques like exercises, manual therapy, modalities, and assistive devices. These interventions are geared towards assisting patients in reaching their objectives and mitigating discomfort.
                Monitoring and Reporting: OTA/COTAs vigilantly track patient progress and response to treatments. They meticulously document treatment outcomes and promptly relay this data to OTs. Additionally, they promptly notify OTs of any alterations or issues in the patient's condition.
                Education and Guidance: OTA/COTAs educate patients, their families, or caregivers on activity execution and equipment utilization necessary for recovery or well-being. They provide valuable insights on injury prevention and complications avoidance, fostering motivation for adherence to treatment regimens.
                Safety and Quality Assurance: OTA/COTAs ensure a clean and secure environment for patients' therapy. Adherence to ethical and legal standards is a priority, respecting patient rights and privacy while maintaining the workspace and therapy equipment's hygienic standards.
                Join Capstone Health to advance your OTA/COTA career and contribute to enriching patient well-being.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Speech Language Pathologist (SLP)</JobTitle>
              <JobDescription>
                At Capstone Health, we value the impactful role of a speech-language pathologist (SLP) in enhancing communication and swallowing abilities. SLPs address diverse challenges across ages and conditions, spanning developmental disorders, neurological ailments, injuries, or cancers. Key functions of an SLP encompass:

                Assessment and Diagnosis: SLPs employ tests and tools to evaluate speech, language, voice, and swallowing capabilities. They identify problem causes, severity, and impacts on daily life.
                Tailored Treatment: SLPs craft personalized plans, delivering therapy through exercises, techniques, devices, or education. Monitoring and documentation of progress and outcomes are integral.
                Prevention and Education: SLPs guide patients and families in managing and enhancing their quality of life, while preventing or mitigating communication and swallowing issues. SLPs excel in various domains, including hospitals, clinics, schools, nursing homes, home health care, or private practice, often collaborating with healthcare peers.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Pharmacy Technician</JobTitle>
              <JobDescription>
                Capstone Health recognizes the pivotal role of pharmacy technicians in patient care. Pharmacy technicians collaborate with pharmacists, aiding in medication preparation and dispensing across diverse environments like retail pharmacies, hospitals, clinics, and mail-order setups. Key responsibilities of pharmacy technicians include:
                Prescription Preparation: Selecting suitable medications in terms of drug, brand, form, strength, and quantity. Accurate labeling, packaging, and proper storage of medications.
                Inventory Management: Overseeing medication stocks, monitoring expiration dates, and restocking when necessary. Responsible disposal of expired or damaged drugs.
                Customer Interaction: Addressing customer inquiries, verifying identities and insurance details, and processing payments or co-pays.
                Prescriber Communication: Collaborating with doctors for prescription information, clarifications, dosage, refills, and authorizations. Notifying of potential drug interactions or allergies. 
                Join Capstone Health to excel in your pharmacy technician career and contribute to patient well-being.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Social Worker</JobTitle>
              <JobDescription>
                Discover the profound impact of social work within our allied health services. Social workers are compassionate professionals who guide individuals in crisis towards stability. Offering counseling, referrals, and valuable information, they enhance well-being and ensure fairness. Our social workers operate across diverse settings like hospitals, clinics, schools, and community organizations. They specialize in areas like mental health, disability, and substance abuse. Roles encompass needs assessment, intervention planning, emotional support, and empowerment. Collaborating with other professionals, they coordinate resources for clients, maintaining confidentiality and records. At Capstone Health, Social Workers play a pivotal role in transforming lives and fostering well-being.
              </JobDescription>
            </JobCard>
          </JobsGrid>
        </ContentSection>

        <ContactBanner>
          <ContactText>DO YOU HAVE A QUESTION?</ContactText>
          <Button to="tel:+15154462266">Call (515) 446-2266</Button>
        </ContactBanner>
      </PageContainer>
      </div>
    </div>
  );
};

export default Therapy;