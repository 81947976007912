// src/pages/Cardiopulmonary.jsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackToHomeLink from '../components/BackToHomeLink';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  background-color: #28346c;
  color: white;
  padding: 4rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: center;
`;

const HeroTitle = styled.h1`
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-size: 2.5rem;
`;

const HeroDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: white;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const CTASection = styled.div`
  background-color: #f8f9fa;
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CTATitle = styled.h2`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.8rem;
`;

const CTADescription = styled.p`
  color: #54698d;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #28346c;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1e2756;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ContentSection = styled.div`
  margin-bottom: 3rem;
`;

const JobsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
`;

const JobCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const JobTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const JobDescription = styled.p`
  color: #54698d;
  line-height: 1.6;
`;

const ContactBanner = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContactText = styled.h3`
  color: #28346c;
  margin: 0;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;
const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const Cardiopulmonary = () => {

  useEffect(() => {
    document.title = "Cardiopulmonary Jobs | Advance Your Healthcare Career with Capstone";
      
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Explore top cardiopulmonary jobs with Capstone. Find rewarding opportunities for respiratory therapists and cardiovascular professionals in leading healthcare facilities.');
    }
  }, []);

  return (
    <div>
      <VisuallyHidden>Cardiopulmonary Jobs – Explore Careers in Respiratory & Cardiac Care</VisuallyHidden>
    <div>
     
      <PageContainer>
      <BackToHomeLink />
        <HeroSection>
          <HeroTitle>Cardiopulmonary</HeroTitle>
          <HeroDescription>
            Explore a world of opportunities in cardiopulmonary practitioners with Capstone Health. We specialize in connecting skilled professionals to vital roles such as Respiratory Therapist (RRT), Polysomnographer, EEG Technologist, Anesthesia Technician, and EKG Technologist. Whether it's providing advanced respiratory care, monitoring sleep disorders, conducting EEG tests, assisting anesthesiologists, or analyzing cardiac rhythms, Capstone Health empowers you to excel. Join us to contribute to cutting-edge advancements in cardiopulmonary healthcare, making a significant impact on patient well-being. Your expertise is our priority.
          </HeroDescription>
        </HeroSection>

        <CTASection>
          <CTATitle>Ready to take the next step in your Cardiopulmonary career?</CTATitle>
          <CTADescription>
            Join Capstone Health today and unlock a world of opportunities in healthcare.
            Your expertise is in demand – let's make a difference together.
          </CTADescription>
          <Button to="/contact">Contact Us</Button>
        </CTASection>

        <ContentSection>
          <JobsGrid>
            <JobCard>
              <JobTitle>Respiratory Therapist (RRT)</JobTitle>
              <JobDescription>
                Elevate patient care as a Respiratory Therapist with Capstone Health. Your expertise in assessing, treating, and managing respiratory conditions is invaluable. Collaborate with medical teams to administer therapies, perform diagnostics, and ensure optimal lung function. Capstone Health offers you the platform to excel in critical care settings, making a significant impact on patient well-being. Join our network of professionals dedicated to advancing respiratory health and contributing to cutting-edge advancements in the field. Your skills drive our commitment to excellence.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Polysomnographer</JobTitle>
              <JobDescription>
                Join Capstone Health as a Polysomnographer and play a vital role in diagnosing sleep disorders. Your expertise in conducting sleep studies and analyzing data is essential for improving patients' quality of life. Collaborate with medical teams to provide accurate assessments and personalized treatment plans. With Capstone Health, you'll contribute to enhancing sleep health, making a real difference in patients' lives. Your skills and dedication drive our commitment to excellence in the field of sleep medicine.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>EEG Technologist</JobTitle>
              <JobDescription>
                Capstone Health welcomes EEG Technologists to contribute to neurological care. Your proficiency in conducting electroencephalograms (EEGs) aids in diagnosing brain disorders. Collaborate with medical teams to perform tests, analyze results, and provide critical insights for patient treatment plans. Your expertise enhances patient outcomes and advances the field of neurology. Join Capstone Health to showcase your skills and dedication, making a significant impact on the well-being of those in need of neurological care. Your commitment aligns with our pursuit of excellence.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Anesthesia Technician</JobTitle>
              <JobDescription>
                Capstone Health invites Anesthesia Technicians to enhance surgical care. Your expertise in preparing equipment, monitoring patients, and assisting anesthesia providers is pivotal for safe and efficient procedures. Collaborate with medical teams to ensure patients' comfort and well-being throughout surgeries. Your skills contribute to optimal patient outcomes and smooth operations. By joining Capstone Health, you become a key player in delivering high-quality healthcare, leaving a positive mark on the surgical experience for both patients and medical professionals.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>EKG Technologist</JobTitle>
              <JobDescription>
                Become a vital part of healthcare with Capstone Health. as an EKG Technologist. Your proficiency in performing electrocardiograms (EKGs) aids in diagnosing heart conditions. Collaborate with medical teams to capture accurate data and provide crucial insights for patient care. Your expertise contributes to enhancing cardiac health and improving lives. Join Capstone Health to showcase your skills and dedication, playing a significant role in promoting heart wellness. Your commitment aligns with our mission of excellence in cardiovascular care.
              </JobDescription>
            </JobCard>
          </JobsGrid>
        </ContentSection>

        <ContactBanner>
          <ContactText>DO YOU HAVE A QUESTION?</ContactText>
          <Button to="tel:+15154462266">Call (515) 446-2266</Button>
        </ContactBanner>
      </PageContainer>
      </div>
    </div>
  );
};

export default Cardiopulmonary;