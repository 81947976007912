// src/components/BackToHomeLink.jsx
import React from 'react';
import styled from 'styled-components';

const StyledBackLink = styled.a`
  display: inline-block;
  margin-bottom: 1.5rem;
  color: #28346c;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const BackToHomeLink = ({ text = 'Back to Home' }) => {
  // Function to get the root domain of the current site
  const getRootDomain = () => {
    // Get the current hostname (e.g., "subdomain.example.com")
    const hostname = window.location.hostname;
    
    // Extract protocol (http/https)
    const protocol = window.location.protocol;
    
    // For localhost development
    if (hostname === 'localhost' || hostname.match(/^(\d{1,3}\.){3}\d{1,3}$/)) {
      return `${protocol}//${hostname}${window.location.port ? ':' + window.location.port : ''}`;
    }
    
    // Get the main domain without subdomains
    // This handles cases like "subdomain.example.com" -> "example.com"
    const domainParts = hostname.split('.');
    let rootDomain;
    
    // If it has enough parts to potentially have a subdomain
    if (domainParts.length > 2) {
      // Take the last two parts (example.com)
      rootDomain = domainParts.slice(-2).join('.');
    } else {
      // Already a root domain (example.com)
      rootDomain = hostname;
    }
    
    return `${protocol}//${rootDomain}`;
  };

  return (
    <StyledBackLink href={getRootDomain()}>
      &larr; {text}
    </StyledBackLink>
  );
};

export default BackToHomeLink;