// src/pages/AboutUs.jsx
import React, { useEffect } from 'react'; 
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  background-color: #28346c;
  color: white;
  padding: 4rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

const HeroTitle = styled.h1`
  margin-bottom: 1rem;
  color: white;
  text-align: center;
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.9;
  color: white;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const ContentSection = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: #28346c;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
`;

const Paragraph = styled.p`
  margin-bottom: 1.5rem;
  color: #54698d;
  line-height: 1.8;
  font-size: 1.05rem;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const FeatureCard = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.3rem;
`;

const FeatureDescription = styled.p`
  color: #54698d;
  line-height: 1.6;
`;

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const AboutUs = () => {

  useEffect(() => {
    document.title = "About Capstone | Leading Healthcare Staffing & Recruitment Agency";
      
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Learn about Capstone, a trusted healthcare staffing agency connecting professionals with top opportunities. Explore our mission, values, and commitment to excellence.');
    }
  }, []);

  return (
    <div>
      <VisuallyHidden>About Capstone – Excellence in Healthcare Staffing</VisuallyHidden>
    <div>
      <PageContainer>
        <HeroSection>
          <HeroTitle>About Capstone Health</HeroTitle>
          <HeroSubtitle>
            Illuminating Healthcare Connections with Excellence
          </HeroSubtitle>
        </HeroSection>

        <ContentSection>
          <Paragraph>
            At Capstone Health, we're here to simplify your journey, whether you're seeking the right talent or your dream job. Our legacy of excellence remains unwavering as we transition to our new name, Capstone Health. With a deep reservoir of talent, we are your go-to staffing agency, committed to forging successful connections.
          </Paragraph>
          
          <Paragraph>
            Our Account Managers and Talent Recruiters are at the forefront of creating triumphant matches. We believe in building meaningful relationships, and it's through these connections that we thrive. Over the past 20+ years, we've honed a unique approach to making the perfect fit a reality. Our secret ingredient? Recognizing that we are humans assisting fellow humans. This perspective radiates through our talent recruitment process, as we aim to understand your aspirations, needs, and desires.
          </Paragraph>
          
          <Paragraph>
            Capstone Health has evolved into a premier boutique recruitment agency, specializing in crafting the right connections that illuminate opportunities. Our expertise shines particularly bright in the fields of Nursing Jobs, Allied Health Jobs, and per diem Jobs. We bridge the gap between small companies and major corporations, fostering connections that transform lives. Our commitment to this noble task remains unwavering, and it's what defines us at Capstone Health.
          </Paragraph>
          
          <Paragraph>
            Step into a world where talent finds its perfect home and employers discover their ideal match. Welcome to Capstone Health, where brilliance in healthcare staffing thrives.
          </Paragraph>

          <SectionTitle>Why Choose Capstone Health</SectionTitle>
          <Paragraph>Your Partner in Exceptional Healthcare Staffing</Paragraph>
          
          <FeatureGrid>
            <FeatureCard>
              <FeatureTitle>Extensive Expertise</FeatureTitle>
              <FeatureDescription>
                With 20+ years of experience in the healthcare staffing industry, we bring a wealth of knowledge and expertise to the table.
              </FeatureDescription>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureTitle>Deep Talent Pool</FeatureTitle>
              <FeatureDescription>
                We have access to a vast network of highly skilled healthcare professionals, ensuring that we can find the perfect fit for your staffing needs.
              </FeatureDescription>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureTitle>Personalized Approach</FeatureTitle>
              <FeatureDescription>
                We take the time to understand your unique requirements, tailoring our solutions to meet your specific needs and goals.
              </FeatureDescription>
            </FeatureCard>
          </FeatureGrid>

          <SectionTitle>Guided Assistance</SectionTitle>
          <Paragraph>Supporting You Every Step of the Staffing Process</Paragraph>
          
          <FeatureGrid>
            <FeatureCard>
              <FeatureTitle>Industry Knowledge</FeatureTitle>
              <FeatureDescription>
                We stay up-to-date with the latest trends and changes in the healthcare industry, ensuring that we provide informed solutions.
              </FeatureDescription>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureTitle>Proven Success</FeatureTitle>
              <FeatureDescription>
                Our track record of successful matches and satisfied clients speaks to our ability to deliver results.
              </FeatureDescription>
            </FeatureCard>
            
            <FeatureCard>
              <FeatureTitle>Work Rewardingly!™</FeatureTitle>
              <FeatureDescription>
                Connect with Capstone Health Today.
              </FeatureDescription>
            </FeatureCard>
          </FeatureGrid>
        </ContentSection>
      </PageContainer>
      </div>
    </div>
  );
};

export default AboutUs;