// src/pages/Laboratory.jsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackToHomeLink from '../components/BackToHomeLink';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.div`
  background-color: #28346c;
  color: white;
  padding: 4rem 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: center;
`;

const HeroTitle = styled.h1`
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-size: 2.5rem;
`;

const HeroDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: white;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const CTASection = styled.div`
  background-color: #f8f9fa;
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CTATitle = styled.h2`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.8rem;
`;

const CTADescription = styled.p`
  color: #54698d;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #28346c;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1e2756;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ContentSection = styled.div`
  margin-bottom: 3rem;
`;

const InfoCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const InfoTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const InfoText = styled.p`
  color: #54698d;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const SectionTitle = styled.h2`
  color: #28346c;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  text-align: center;
`;

const BenefitsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
`;

const BenefitCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
`;

const BenefitText = styled.p`
  color: #54698d;
  line-height: 1.5;
  margin: 0;
`;

const JobsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
`;

const JobCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const JobTitle = styled.h3`
  color: #28346c;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const JobDescription = styled.p`
  color: #54698d;
  line-height: 1.6;
`;

const ContactBanner = styled.div`
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContactText = styled.h3`
  color: #28346c;
  margin: 0;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const Laboratory = () => {

  useEffect(() => {
    document.title = "Medical Laboratory Tech Jobs | Explore Lab Careers with Capstone";
      
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Discover the best Medical Laboratory Tech jobs with Capstone. Explore rewarding opportunities in diagnostic labs and take the next step in your medical lab technology career.');
    }
  }, []);

  return (
    <div>
      <div>
      <VisuallyHidden>Medical Laboratory Tech Jobs – Build Your Career in Diagnostic Labs</VisuallyHidden>
      <PageContainer>
      <BackToHomeLink />
        <HeroSection>
          <HeroTitle>Capstone Health Laboratory Technologist</HeroTitle>
          <HeroDescription>
            Capstone Health offers a specialized staffing service for Laboratory Technologists, focusing on roles such as Medical Technologist (MT). As a Medical Technologist, you play a pivotal role in the healthcare field by conducting laboratory tests, analyzing samples, and providing essential diagnostic information to medical professionals. Capstone Health understands the importance of your expertise in ensuring accurate patient diagnoses. Join our network of skilled laboratory professionals and be a part of healthcare teams that make a significant impact on patient care. Elevate your laboratory career with Capstone Health today.
          </HeroDescription>
        </HeroSection>

        <CTASection>
          <CTATitle>Seeking Laboratory Technologist Opportunities?</CTATitle>
          <CTADescription>
            Explore a diverse array of laboratory technologist positions nationwide with Capstone Health.
            Set out on your laboratory career journey today.
          </CTADescription>
          <Button to="/contact">Contact Us</Button>
        </CTASection>

        <ContentSection>
          <InfoCard>
            <InfoTitle>Unlocking a Universe of Prospects for Laboratory Technologist</InfoTitle>
            <InfoText>
              Capstone Health offers a specialized staffing service for Laboratory Technologists, focusing on roles such as Medical Technologist (MT). As a Medical Technologist, you play a pivotal role in the healthcare field by conducting laboratory tests, analyzing samples, and providing essential diagnostic information to medical professionals. Capstone Health understands the importance of your expertise in ensuring accurate patient diagnoses. Join our network of skilled laboratory professionals for a rewarding journey in Allied Health Careers and Allied Healthcare Jobs to make a significant impact on patient care. Elevate your laboratory career with Capstone Health today.
            </InfoText>
          </InfoCard>
          
          <SectionTitle>Advantages for Capstone Health's Laboratory Technologist</SectionTitle>
          <BenefitsSection>
            <BenefitCard>
              <BenefitText>Comprehensive healthcare plans</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Dental and vision insurance</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Life insurance coverage</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Access to mental health resources</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Financial coaching services</BenefitText>
            </BenefitCard>
            <BenefitCard>
              <BenefitText>Supplementary income replacement plans</BenefitText>
            </BenefitCard>
          </BenefitsSection>

          <JobsGrid>
            <JobCard>
              <JobTitle>Medical Technologist (MT)</JobTitle>
              <JobDescription>
                As a Medical Technologist (MT), you play a vital role in healthcare diagnostics and patient care. Your responsibilities include conducting various laboratory tests, analyzing samples, and interpreting results to aid in disease diagnosis and treatment. Your precision and expertise contribute to accurate medical decisions. MTs collaborate with healthcare teams, ensuring the quality of patient care. Your role requires a Bachelor's degree in medical technology or a related field, as well as certification. Join Capstone Health to embark on a rewarding career journey as a skilled Medical Technologist.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Medical Lab Technician (MLT)</JobTitle>
              <JobDescription>
                As a Medical Lab Technician (MLT), you are an essential part of the healthcare system. Your role involves conducting laboratory tests, analyzing samples, and assisting in diagnosing medical conditions. Working under the supervision of medical technologists or pathologists, you ensure accurate results that contribute to patient care. MLTs play a crucial role in disease detection, treatment, and monitoring. Your attention to detail and technical skills are paramount in delivering reliable test outcomes. Join Capstone Health to harness your expertise as a Medical Lab Technician and make a meaningful impact on patient well-being.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Clinical Lab Scientist (CLS)</JobTitle>
              <JobDescription>
                As a Clinical Lab Scientist (CLS), you hold a pivotal position in the healthcare landscape. Your expertise in conducting complex laboratory tests, analyzing specimens, and interpreting results significantly impacts patient diagnoses and treatment plans. With a thorough understanding of medical technology and a commitment to precision, you contribute to delivering accurate and timely information to medical professionals. Join Capstone Health to advance your career as a Clinical Lab Scientist, utilizing your skills to enhance patient care and medical outcomes.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Cytotechnologist</JobTitle>
              <JobDescription>
                As a Cytotechnologist, you play a critical role in early disease detection and patient care. Specializing in examining cellular specimens for signs of abnormalities, you contribute directly to diagnoses and treatment decisions. Your expertise in identifying cancerous and precancerous cells through microscopic analysis aids in saving lives. Join Capstone Health to elevate your career as a Cytotechnologist, working with cutting-edge medical technology and making a lasting impact on patient health and well-being. Your skills and dedication are at the forefront of improving healthcare outcomes.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Histotechnologist</JobTitle>
              <JobDescription>
                As a Histotechnologist, you're a key player in the diagnostic process, preparing tissue samples for microscopic examination. Your precision and expertise ensure accurate results in diagnosing diseases like cancer. Working behind the scenes, you contribute significantly to patient care and treatment decisions. By joining Capstone Health, you'll step into a fulfilling role where your skills directly impact healthcare outcomes. Elevate your career as a Histotechnologist and contribute to advancing medical knowledge and patient well-being. Your dedication makes a difference in every slide you prepare.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Pathology Assistant</JobTitle>
              <JobDescription>
                As a Pathology Assistant, you play a vital role in the diagnostic process by assisting pathologists with post-mortem examinations and surgical pathology procedures. Your keen attention to detail ensures accurate specimen collection, processing, and analysis. By collaborating closely with the medical team, you contribute to accurate disease diagnosis and patient care decisions. Join Capstone Health to enhance your career as a Pathology Assistant, contributing to the advancement of medical knowledge and patient well-being. Your expertise shapes the path towards accurate diagnoses and better patient outcomes.
              </JobDescription>
            </JobCard>
            <JobCard>
              <JobTitle>Phlebotomists</JobTitle>
              <JobDescription>
                Join Capstone Health as a skilled phlebotomist and excel in your impactful role. Engage patients with your compassionate communication, guiding them through blood draw process. With meticulous attention, ensure accurate patient identification and precise labeling of blood samples. Utilize varied techniques for blood collection, including venipuncture, finger pricks, and heel pricks. Facilitate blood transfusions when required, assisting medical teams in diagnoses and patient care. Maintain organized equipment and meticulously document procedures and patient data in medical records. Capstone Health is your partner for growth, connecting you to healthcare opportunities that recognize your expertise and dedication.
              </JobDescription>
            </JobCard>
          </JobsGrid>
        </ContentSection>

        <ContactBanner>
          <ContactText>DO YOU HAVE A QUESTION?</ContactText>
          <Button to="tel:+15154462266">Call (515) 446-2266</Button>
        </ContactBanner>
      </PageContainer>
      </div>
    </div>
  );
};

export default Laboratory;